import UsersPage from '../pages/UsersPage';
import Settings from '../pages/Settings';
import OrdersPage from '../pages/OrdersPage';
import UsersList from '../components/user/UsersList';
import DetailUser from '../components/user/DetailUser';
import AddUser from '../components/user/AddUser';
import HandbookPage from '../pages/HandbookPage';
import AddCity from '../components/handbook/AddCity';
import CityList from '../components/handbook/CityList';
import FilialList from '../components/handbook/FilialList';
import AddFilial from '../components/handbook/AddFilial'; 
import EditUser from '../components/user/EditUser';
import EditCity from '../components/handbook/EditCity';
import DetailCity from '../components/handbook/DetailCity';
import Dashboard from '../components/dashboard/Dashboard';
import EditFilial from '../components/handbook/EditFilial';
import DetailFilial from '../components/handbook/DetailFilial';
import OrdersList from '../components/orders/OrdersList';
import DetailOrder from '../components/orders/DetailOrder';
import PromocodesPage from '../pages/PromocodesPage';
import GoodsPage from '../pages/GoodsPage';
import PushMessage from '../pages/PushMessage/';

export const handbookPages = [
    { name: 'cities', title: 'Города', path: '/handbook/cities', component: CityList, fit: true },
    { name: 'addCity', title: 'Добавление города', path: '/handbook/cities/add', component: AddCity, fit: true, disableSidebar: true, access: 4 },
    { name: 'editCity', title: 'Редактирование города', path: '/handbook/cities/edit/:id', component: EditCity, fit: true, disableSidebar: true, access: 4 },
    { name: 'filials', title: 'Филиалы', path: '/handbook/filials', component: FilialList, fit: true },
    { name: 'addFilial', title: 'Добавление филиала', path: '/handbook/filials/add', component: AddFilial, fit: true, disableSidebar: true, access: 4 },
    { name: 'editFilial', title: 'редактирование филиала', path: '/handbook/filials/edit/:id', component: EditFilial, fit: true, disableSidebar: true, access: 4 },
    { name: 'viewCity', title: 'Город', path: '/handbook/cities/:id', component: DetailCity, fit: true, disableSidebar: true },
    { name: 'viewFilial', title: 'Филиал', path: '/handbook/filials/:id', component: DetailFilial, fit: true, disableSidebar: true },
];

export const pages = [
    { name: 'dashboard', title: 'Панель управления', path: '/', component: Dashboard },
    { name: 'users', title: 'Пользователи', path: '/users', component: UsersPage, fit: true },
    { name: 'orders', title: 'Заказы', path: '/orders', component: OrdersPage },
    { name: 'goods', title: 'Товары', path: '/goods', component: GoodsPage },
    { name: 'promocodes', title: 'Промокоды', path: '/promocodes', component: PromocodesPage, access: 4 },
    { name: 'pushMessage', title: 'Push сообщения', path: '/pushmessage', component: PushMessage, access: 4 },
    { name: 'handbook', title: 'Справочники',  path: '/handbook', component: HandbookPage, sub: handbookPages, access: 4 },
    { name: 'settings', title: 'Настройки', path: '/settings', component: Settings, access: 4 }
];

export const userPages = [
    { name: 'list', title: 'Пользователи', path: '/users', component: UsersList, fit: true },
    { name: 'addManager', title: 'Добавление менеджера', path: '/users/addManager', component: AddUser },
    { name: 'view', title: 'Детальная информация', path: '/users/:id', component: DetailUser },
    { name: 'edit', title: 'Редактирование пользователя', path: '/users/edit/:id', component: EditUser },
];

export const ordersPages = [
    { name: 'list', title: 'Заказы', path: '/orders', component: OrdersList, fit: true },
    { name: 'view', title: 'Детальная информация', path: '/orders/:id', component: DetailOrder },
];