const initialState = {
    chanels: [],
    sended: {
      list: [],
      paging: {
        start: 0,
        limit: 10
      },
      sorting: {
        field: 'id',
        type: 'desc'
      },
      count: 0
    },
    deleted: {
      list: [],
      paging: {
        start: 0,
        limit: 10
      },
      sorting: {
        field: 'id',
        type: 'desc'
      },
      count: 0
    },
    newMessage: {
      chanels: [],
      theme: '',
      message: ''
    }
};

export default function messages(state = initialState, action) {
  switch(action.type) {
      case 'SET_CHANELS': {
          return {
              ...state,
              chanels: action.chanels
          };
      }
      case 'SET_MESSAGES': {
        return {
            ...state,
            [action.group]: {
              ...state[action.group],
              list: action.messages
            }
        };
      }
      case 'SET_MESSAGES_PAGING': {
        return {
            ...state,
            [action.group]: {
              ...state[action.group],
              paging: action.paging
            }
        };
      }
      case 'SET_MESSAGES_COUNT': {
        return {
            ...state,
            [action.group]: {
              ...state[action.group],
              count: action.count
            }
        };
      }
      case 'SET_NEW_MESSAGE_FORM': {
        return {
          ...state,
          newMessage: {
            ...state.newMessage,
            [action.field]: action.value
          }
        }
      }
      case 'RESET_NEW_MESSAGE_FORM': {
        return {
          ...state,
          newMessage: initialState.newMessage
        }
      }
    default: return state;
  }
}