import React from 'react';

export default function Switch({ checked = false, onChange, nomargin }) {
    return (
        <span className="kt-switch kt-switch--icon">
            <label style={{ marginBottom: nomargin ? 0 : '0.5rem' }}>
                <input onClick={(e) => onChange(e.target.checked)} type="checkbox" defaultChecked={checked} name=""/>
                <span></span>
            </label>
        </span>
    );
}