import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { singIn } from '../action/auth';
import { Redirect } from "react-router-dom";
import { getCookie } from '../utils/cookie';

import '../styles/login-3.css';

class LoginPage extends Component {
    state = {
        login: '',
        password: '',
        remember: false,
        redirectToReferrer: false
    }

    componentWillUnmount() {
        this.setState = (state, callback)=> {
            return;
        };
    }
    

    setValue = (field) => (e) => {
        if (field === 'remember') {
            this.setState({[field]: !this.state.remember});  
            return;
        }
        this.setState({[field]: e.target.value});
    }

    singIn = (e) => {
        e.preventDefault();
        const {login, password, remember} = this.state;
        this.props.singIn(login, password, remember).then(result => {
            if (result) {
                this.setState({ redirectToReferrer: true });
            }
        });;
    }

    renderSingInForm() {
        return (
            <div className="kt-login__signin">
                <div className="kt-login__head">
                    <h3 className="kt-login__title">Вход в систему</h3>
                </div>

                <form className="kt-form" onSubmit={this.singIn}>
                    <div className="input-group">
                        <input
                            onChange={this.setValue('login')}
                            className="form-control"
                            type="text"
                            placeholder="Логин"
                            autoComplete="off"
                            value={this.state.login}
                            disabled={this.props.loanding}
                        />
                    </div>
                    <div className="input-group">
                        <input
                            onChange={this.setValue('password')}
                            className="form-control"
                            type="password"
                            placeholder="Пароль"
                            value={this.state.password}
                            disabled={this.props.loanding}
                        />
                    </div>
                    <div className="row kt-login__extra">
                        <div className="col">
                            <label className="kt-checkbox">
                                <input
                                    onChange={this.setValue('remember')}
                                    type="checkbox"
                                    name="remember"
                                    checked={this.state.remember}
                                    disabled={this.props.loanding}
                                /> Запомнить меня
                                <span></span>
                            </label>
                        </div>
                    </div>
                    {
                        this.props.error && (
                            <div className="alert alert-solid-danger alert-bold" role="alert">
                                <div className="alert-text">{this.props.error}</div>
                            </div> 
                        )
                    }
                    <div className="kt-login__actions">
                        <button disabled={this.props.loanding || (!this.state.login || !this.state.password)} className="btn btn-brand btn-elevate kt-login__btn-primary kt-spinner ">
                            {
                                this.props.loanding ? (
                                    <Fragment>
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        Вход...
                                    </Fragment>
                                ) : 'Войти'
                            }
                            
                        </button>
                    </div>
                </form>        
            </div>
        );
    }
    render() {
        let { redirectToReferrer } = this.state;
        if (getCookie('auth') || redirectToReferrer) return <Redirect to={'/'} />;

        return (
            <div className="kt-grid kt-grid--ver kt-grid--root">
                <div className="kt-grid kt-grid--hor kt-grid--root  kt-login kt-login--v3 kt-login--signin" id="kt_login">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" style={{ backgroundImage: 'url(/images/bg-3.jpg)' }}>
                        <div className="kt-grid__item kt-grid__item--fluid kt-login__wrapper">
                            <div className="kt-login__container">
                                <div className="kt-login__logo">
                                    <img src="/images/logo1.png" alt="123"/>
                                </div>
                                
                                { this.renderSingInForm() }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    loanding: state.loaders.singIn,
    error: state.errors.singIn
});

const mapDispatchToProps = {
    singIn: (login, password, remember) => singIn(login, password, remember)
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);