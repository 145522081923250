import React from 'react';

const Loader = () => {
    return (
        <div className="loader">
            <div>Загружаю данные</div>
            <div className="kt-spinner kt-spinner--lg kt-spinner--info">
            </div>
        </div>
    );
};

export default Loader;