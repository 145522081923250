export function setError(error, field) {
    return dispatch => {
        
        if (error.status === 404) {
            dispatch({ type: 'SET_ERROR', field: 'global', value: '404: Страница не найдена' });
            return;
        }
        if (error.status === 500) {
            dispatch({ type: 'SET_ERROR', field: 'global', value: '500: Ошибка загрузки данных. Обратитесь к администратору' });
            return;
        }
        if (error.status === 506) {
            dispatch({ type: 'SET_ERROR', field: field, value: 'Ошибка сохранения данных. Попробуйте еще раз или обратитесь к администратору' });
            return;
        }
        if (error.status === 507) {
            dispatch({ type: 'SET_ERROR', field: field, value: 'Ошибка удаления данных. Попробуйте еще раз или обратитесь к администратору' });
            return;
        }
        if (error.status === 508) {
            dispatch({ type: 'SET_ERROR', field: field, value: 'Ошибка обновления данных. Попробуйте еще раз или обратитесь к администратору' });
            return;
        }
        dispatch({ type: 'SET_ERROR', field: field, value: 'Сервис не доступен. Обратитесь к администратору' });
    }
}

export function resetError(field) {
    return dispatch => {
        dispatch({ type: 'SET_ERROR', field: field, value: null });
    }
}