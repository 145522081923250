import React, { Component } from 'react';

class Footer extends Component {
    render() {
        return (
            <div className="kt-footer kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop" id="kt_footer">
                <div className="kt-container  kt-container--fluid ">
                    <div className="kt-footer__copyright">
                    2019&nbsp;&copy;&nbsp;SushiBox
                    </div>
                    
                </div>
            </div>
        );
    }
}

export default Footer;