const initialState = {
    list: [],
    categories: []
};

export default function goods(state = initialState, action) {
    switch(action.type) {
      case 'SET_GOODS': {
        return {
            ...state,
            list: action.goods
        };
      }
      case 'SET_CATEGORIES': {
        return {
            ...state,
            categories: action.categories
        };
      }
      default: return state;
    }
  }