const initialState = {
    list: [],
    detail: null,
    edited: null,
    delete: null,
    add: null,
    paging: {
      start: 0,
      limit: 10
    },
    sorting: {
      field: 'id',
      type: 'desc'
    },
    count: 0
};

export default function promocodes(state = initialState, action) {
  switch(action.type) {
    case 'SET_PROMOCODES': {
          return {
              ...state,
              list: action.promocodes
          };
    }
    case 'SET_PROMOCODES_PAGING': {
      return {
          ...state,
          paging: action.paging
      };
}
    case 'SET_PROMOCODES_COUNT': {
      return {
          ...state,
          count: action.count
      };
    }
    case 'SET_PROMOCODE': {
      return {
          ...state,
          detail: action.promocode
      };
    }
    case 'SET_EDITED_PROMOCODE': {
        return {
            ...state,
            edited: action.promocode
        };
    }
    case 'SET_ADD_PROMOCODE': {
        return {
            ...state,
            add: action.promocode
        };
    }
    case 'SET_DELETE_PROMOCODE': {
        return {
            ...state,
            delete: action.promocode
        };
    }
    default: return state;
  }
}