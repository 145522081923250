const initialState = {
    cities: [],
    filials: [],
    add: {},
    paging: {
      city: {
        start: 0,
        limit: 10
      },
      filial: {
        start: 0,
        limit: 10
      }
    },
    sorting: {
      city: {
        field: 'name',
        type: 'asc'
      },
      filial: {
        field: 'city',
        type: 'desc'
      }
    },
    count: {
      cities: 0,
      filials: 0
    },
    city: {
      detail: {},
      edit: null
    },
    filial: {
      detail: {},
      edit: null
    }
};

export default function handbook(state = initialState, action) {
  switch(action.type) {
    case 'SET_CITIES': {
          return {
              ...state,
              cities: action.cities
          };
    }
    case 'SET_CITY': {
      return {
        ...state,
        city: {
          ...state.city,
          detail: action.city
        }
      }
    }
    case 'SET_FILIAL': {
      return {
        ...state,
        filial: {
          ...state.filial,
          detail: action.filial
        }
      }
    }
    case 'SET_EDIT_FILIAL': {
      return {
        ...state,
        filial: {
          ...state.filial,
          edit: action.data
        }
      }
    }
    case 'SET_EDIT_CITY': {
      return {
        ...state,
        city: {
          ...state.city,
          edit: action.data
        }
      }
    }
    
    case 'SET_HANDBOOK_PAGING': {
      return {
          ...state,
          paging: {
            ...state.paging,
            [action.field]: action.paging
          }
      };
    }
    case 'SET_HANDBOOK_COUNT': {
      return {
          ...state,
          count: {
            ...state.count,
            [action.field]: action.count
          }
      };
    }
    case 'SET_FILIALS': {
      return {
          ...state,
          filials: action.filials
      };
    }
    case 'SET_ADD_HANDBOOK': {
      return {
          ...state,
          add: action.data
      };
    }
    default: return state;
  }
}