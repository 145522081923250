import React from 'react';
import cn from 'classnames';

function Input({ disableGroup, label, required, onChange, value = "", type = "text", placeholder, error, disabled, description }) {
    return (
        <div className={cn({ 'form-group': !disableGroup})}>
            {label && <label>{label}{required ? " *" : ""}</label>}
            <input
                onChange={onChange}
                defaultValue={value || value === 0 ? value : ''}
                type={type}
                className={cn({ 'form-control': true, 'is-invalid': error })}
                autoComplete="false"
                placeholder={placeholder}
                disabled={disabled}
            />
            {
                description && (
                    <span style={{ marginTop: 5 }} className="form-text text-muted">{description}</span>
                )
            }
            {
                error && (
                    <div className="error invalid-feedback">
                        {error}
                    </div>
                )
            }
            
        </div>
    );
}

export default Input;