import React, { Component } from 'react';

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1
    },
    text: {
        marginBottom: '25px',
        fontSize: '18px'
    }
}

class LoadingPage extends Component {
    render() {
        return (
            <div className="kt-grid kt-grid--ver kt-grid--root">
                <div className="kt-grid kt-grid--hor kt-grid--root  kt-login kt-login--v3 kt-login--signin" id="kt_login">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" style={{ backgroundImage: 'url(/images/bg-3.jpg)' }}>
                        <div style={styles.container}>
                            <div style={styles.text}>Загрузка данных...</div>
                            <div style={{ marginLeft: '-30px' }} className="kt-spinner kt-spinner--v2 kt-spinner--lg kt-spinner--info"></div>
                        </div>
                       
                    </div>
                </div>
            </div>
        );
    }
}

export default LoadingPage;