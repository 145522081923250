import React from 'react';

const Content = ({ title, fit = false, children }) => {
    return (
        <div className="kt-content kt-subheader--enabled kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
            { children }
		</div>
    );
};

export default Content;