import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Loader from '../components/utils/Loader';
import Portlet from '../components/utils/Portlet';
import Content from '../components/layout/Content';
import ReactPaginate from 'react-paginate';
import List from '../components/promocodes/List';
import { fetchPromocodes, setEditedPromocode, setDeletePromocode, updatePromocode, deletePromocode, addPromocode, setAddPromocode } from '../action/app';
import ErrorContainer from '../components/utils/ErrorContainer';

const styles = {
    loading: {
        minHeight: '500px',
        display: 'flex',
        justifyContent: 'center'
    }
}

class PromocodesPage extends Component {
    componentDidMount() {
        this.props.fetchPromocodes();
    }

    state = {
        currentPage: 0
    }

    setPageLimit = (e) => {
        e.preventDefault();
        const updatedPaging = { start: 0, limit: e.target.value};
        this.setState({ currentPage: 0 });
        this.props.setHandbookPaging('city', updatedPaging);
        this.props.fetchCities();
    }

    handlePageClick = data => {
        const { paging } = this.props;
        this.setState({ currentPage: data.selected }, () => {
            let nextStart =  data.selected * paging.limit;
            const updatedPaging = {...paging, start: nextStart};
            this.props.setHandbookPaging('city', updatedPaging);
            this.props.fetchCities();
        });
    };

    renderPagination() {
        const { count, paging } = this.props;
        const pages = Math.ceil(+count / +paging.limit);

        return (
            <div className="kt-datatable__pager kt-datatable--paging-loaded">
                <ReactPaginate
                    previousLabel={<i className="flaticon2-back"></i>}
                    nextLabel={<i className="flaticon2-next"></i>}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={pages}
                    marginPagesDisplayed={8}
                    pageRangeDisplayed={5}
                    forcePage={this.state.currentPage}
                    onPageChange={this.handlePageClick}
                    containerClassName={'kt-datatable__pager-nav'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                    pageLinkClassName="kt-datatable__pager-link kt-datatable__pager-link-number"
                    previousLinkClassName="kt-datatable__pager-link kt-datatable__pager-link--prev"
                    nextLinkClassName="kt-datatable__pager-link kt-datatable__pager-link--next"
                    activeLinkClassName="kt-datatable__pager-link--active"
                    disabledClassName="kt-datatable__pager-link--disabled"
                    disabled={this.props.add}
                />

                    <div className="kt-datatable__pager-info">
                        <div className="kt-datatable__pager-size">
                            <select disabled={this.props.add} onChange={this.setPageLimit} value={paging.limit} className="form-control">
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                            
                        </div>
                        {
                            paging.start + paging.limit >= count ? (
                                <span className="kt-datatable__pager-detail">Показано {paging.start + 1} - {+count} из {count}</span>
                            ) : (
                                <span className="kt-datatable__pager-detail">Показано {paging.start + 1} - {+paging.start + +paging.limit} из {count}</span>
                            )
                        }
                       
                    </div>
            </div>
    
        );
    }

    addPromocode = () => {
        const promocode = { name: null, value: null, delivery: 'all', persent: 0, active: 0, minPrice: null };
        this.props.setAddPromocode(promocode);
    }

    renderSubHeader() {
        return (
            <div className="kt-subheader kt-grid__item">
                <div className="kt-container  kt-container--fluid ">
                <div className="kt-subheader__main">
                    <h3 className="kt-subheader__title">Промокоды</h3>
                    <span className="kt-subheader__separator kt-subheader__separator--v"></span>
                    <div className="kt-subheader__group" id="kt_subheader_search">
                        <span className="kt-subheader__desc" id="kt_subheader_total">
                            {this.props.count} Всего
                        </span>
                    </div>
                </div>
                
                        <div className="kt-subheader__toolbar">
                            <button disabled={this.props.add} onClick={this.addPromocode} className="btn btn-label-brand btn-bold">
                                Добавить промокод
                            </button>
                        </div>

            </div>
            </div>
        );
    }

    render() {
        if (this.props.loading) {
            return (
                <div style={styles.loading}><Loader /></div>
            );
        }
        
        return (
            <Fragment>
                { this.renderSubHeader() }
                <div className="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <Content>
                    <Portlet fit={true}>
                        <ErrorContainer field="promocodes" style={{ margin: 15 }} hasCloseButton={true} />
                        <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded">
                            <List
                                edited={this.props.edited}
                                deleted={this.props.deleted}
                                setDeletePromocode={this.props.setDeletePromocode}
                                setEditedPromocode={this.props.setEditedPromocode}
                                items={this.props.promocodes}
                                updatePromocode={this.props.updatePromocode}
                                fetchPromocodes={this.props.fetchPromocodes}
                                deletePromocode={this.props.deletePromocode}
                                add={this.props.add}
                                addPromocode={this.props.addPromocode}
                                setAddPromocode={this.props.setAddPromocode}
                            />
                            {
                                this.props.promocodes.length > 0 && this.renderPagination()
                            }
                        </div>
                    </Portlet>
                </Content>
            </div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    promocodes: state.promocodes.list,
    loading: state.loaders.promocodes,
    paging: state.promocodes.paging,
    count: state.promocodes.count,
    edited: state.promocodes.edited,
    deleted: state.promocodes.delete,
    add: state.promocodes.add
});

const mapDispatchToProps = {
    fetchPromocodes: () => fetchPromocodes(),
    setEditedPromocode: (promocode) => setEditedPromocode(promocode),
    setDeletePromocode: (promocode) => setDeletePromocode(promocode),
    updatePromocode: (id, data) => updatePromocode(id, data),
    deletePromocode: (id) => deletePromocode(id),
    addPromocode: (data) => addPromocode(data),
    setAddPromocode: (promocode) => setAddPromocode(promocode)
}

export default connect(mapStateToProps, mapDispatchToProps)(PromocodesPage);