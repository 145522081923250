import React, { Component } from 'react';
import cn from 'classnames';
import ReactDOM from 'react-dom';
const actionMenu = document.body;

function getCoords(elem) {
    // (1)
    var box = elem.getBoundingClientRect();
  
    var body = document.body;
    var docEl = document.documentElement;
  
    // (2)
    var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
    var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;
  
    // (3)
    var clientTop = docEl.clientTop || body.clientTop || 0;
    var clientLeft = docEl.clientLeft || body.clientLeft || 0;
  
    // (4)
    var top = box.top + scrollTop - clientTop;
    var left = box.left + scrollLeft - clientLeft;
  
    return {
      top: top,
      left: left
    };
  }


  class Modal extends Component {
    constructor(props) {
      super(props);
      // Create a div that we'll render the modal into. Because each
      // Modal component has its own element, we can render multiple
      // modal components into the modal container.
      this.el = document.createElement('div');
    }
  
    componentDidMount() {

      actionMenu.appendChild(this.el);
      document.addEventListener('click', this.closeModal);
      window.addEventListener('resize', this.resize);
    }
  
    componentWillUnmount() {
      actionMenu.removeChild(this.el);
      document.removeEventListener('click', this.closeModal);
      window.removeEventListener('resize', this.resize);
    }

    resize = () => {
        this.props.changeCoord();
    }

    
    closeModal = (evt) => {
        this.props.close();
    }
    
    render() {
      // Use a portal to render the children into the element
      return ReactDOM.createPortal(
        // Any valid React child: JSX, strings, arrays, etc.
        this.props.children,
        // A DOM element
        this.el
      );
    }
  }

class ActionMenu extends Component {
    state = {
        active: false,
        top: 0,
        left: 0
    };


    setCoor = () => {
        const { top, left } = getCoords(this.mod);
        const clientHeight = document.body.clientHeight;
        const heightEle = top + 185;
        if (heightEle >= clientHeight) {
            this.setState({ top: top - 185, left: left - 167, active: true });
        } else {
            this.setState({ top: top + 35, left: left - 167, active: true });
        }
    }

    onClick = (e) => {
        e.preventDefault();
        
        let targetElement = e.target; 

        while (!targetElement.classList.contains('dropdown')) {
            targetElement = targetElement.parentNode;
        }
        this.mod = targetElement;
        this.setCoor(this.mod);
    }

    closeModal = () => {
        this.setState({ active: false })
    }

    onAction = (action) => (e) => {
        e.preventDefault();
        action();
    }

    render() {
        return (
            <span style={{ overflow: 'visible', position: 'relative', width: '80px' }}>
                <div className={cn({ dropdown: true, show: this.state.active })}>
                    <a onClick={this.onClick} href="/" className="btn btn-sm btn-clean btn-icon btn-icon-md">
                        <i className="flaticon-more-1"></i>
                    </a>
                    {
                        this.state.active && (
                            <Modal close={this.closeModal} changeCoord={this.setCoor}>
                                <div ref={node => this.el = node}
                                    className="dropdown-menu dropdown-menu-right"
                                    style={{ width: '200px', display: 'block', position: 'absolute', transform: `translate3d(${this.state.left}px, ${this.state.top}px, 0px)`, top: '0px', left: '0px', willChange: 'transform'}}>
                                    <ul className="kt-nav">
                                        {
                                            this.props.actions.map((item, index) => (
                                                <li key={index} className="kt-nav__item">
                                                    <a onClick={this.onAction(item.action)} className="kt-nav__link" href="/">
                                                        <i className={`kt-nav__link-icon ${item.icon}`}></i>
                                                        <span className="kt-nav__link-text">{item.name}</span>
                                                    </a>
                                                </li>
                                            ))
                                        }
                                    
                                    </ul>
                                </div>
                            </Modal>
                        )
                    }
                </div>
            </span>
        );
    }
}

export default ActionMenu;