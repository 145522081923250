import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { setAddForm, fetchUser } from '../../action/user';
import cn from 'classnames';
import { updateUser } from '../../action/user';
import { fetchCities, fetchFilials } from '../../action/handbook';
import Input from '../utils/Input'
import ErrorContainer from '../utils/ErrorContainer';

const emailRegExp = /^[-a-z0-9!#$%&'*+/=?^_`{|}~]+(\.[-a-z0-9!#$%&'*+/=?^_`{|}~]+)*@([a-z0-9]([-a-z0-9]{0,61}[a-z0-9])?\.)*(aero|arpa|asia|biz|cat|com|coop|edu|gov|info|int|jobs|mil|mobi|museum|name|net|org|pro|tel|travel|[a-z][a-z])$/i;

const tabs = [
    { name: 'accaunt', title: 'Аккаунт', titleUser: 'Аккаунт', subTitle: 'Настройка детальной информации', subTitleUser: 'Настройка детальной информации', icon: 'flaticon-globe' },
    { name: 'city', title: 'Город', titleUser: 'Город', subTitle: 'Настройка города обслуживания', subTitleUser: 'Настройка города и адреса доставки', icon: 'flaticon-bus-stop' },
    { name: 'filial', title: 'Филиалы', titleUser: 'Пункт самовывоза', subTitleUser: 'Настройка пункта самовывоза', subTitle: 'Настройка филиалов', icon: 'flaticon-responsive' },
    { name: 'done', title: 'Выполнено!', titleUser: 'Выполнено!', subTitle: 'Проверка и сохранение', subTitleUser: 'Проверка и сохранение', icon: 'flaticon-confetti' }
];

class EditUser extends Component {
    state = {
        step: this.props.location.state && this.props.location.state.step ? this.props.location.state.step : 0,
        errors: {},
        loader: false
    }

    componentWillUnmount() {
        this.props.setAddForm({ accaunt: {}, city: {}, filial: [] });
    }

    componentDidMount() {
        this.props.fetchUser(this.props.match.params.id).then(user => {
            this.props.setAddForm({
                accaunt: {
                    login: user.username,
                    name: user.name,
                    phone: user.phone,
                    email: user.email
                },
                city: {
                    value: user.city.id,
                    street: user.street,
                    house: user.house,
                    room: user.room,
                    entrance: user.entrance
                },
                filial: user.points.map(item => item.id)
            });
            this.props.fetchCities();
            this.props.fetchFilials(user.city.id);
        });
    }

    renderSubHeader() {
        return (
            <div className="kt-subheader kt-grid__item">
                <div className="kt-container  kt-container--fluid ">
                <div className="kt-subheader__main">
                    <h3 className="kt-subheader__title">Редактирование пользователя</h3>
                    <span className="kt-subheader__separator kt-subheader__separator--v"></span>
                </div>        
                <div className="kt-subheader__toolbar">
                    <button onClick={() => this.props.history.push(`/users/${this.props.match.params.id}`)} className="btn btn-default btn-bold">
                        Назад
                    </button>
                </div>
            </div>
            </div>
        );
    }

    onTab = (step) => (e) => {
        e.preventDefault();
        if (this.state.step === 0) {
            this.props.fetchCities();
            this.setState({ step: step });
        } else if (this.state.step === 1) {
            this.props.fetchFilials(this.props.addForm.city.value);
            this.setState({ step: step });
        } else {
            this.setState({ step: step });
        }
    }

    setFormValue = (section, field) => (e) => {
        const { addForm, setAddForm } = this.props;
        if (this.state.errors.login || this.state.errors.email) {
            this.setState({ errors: {} });
        }
        const data = { ...addForm };
        data[section] = data[section] ? data[section] : {};
        data[section][field] = e.target.value;

        if (section === 'city' && field === 'value' && data.filial.length) {
            data.filial = [];
        }
        setAddForm(data);
    }

    setFormFilial = (index) => (e) => {
        const { addForm, setAddForm } = this.props;

        const data = { ...addForm };
        data.filial[index] = e.target.value;

        setAddForm(data);
    }

    isValidLogin(login = "") {
        if (login && !/^.{4,}$/.test(login)) {
            return 'Минимум 4 символов';
        }
        if (this.state.errors.login) {
            return this.state.errors.login;
        }
        return null;
    }

    isValidPass(pass = "") {
        if (pass && !/^.{8,}$/.test(pass)) {
            return 'Минимум 8 символов';
        }
        return null;
    }

    isValidEmail(email = "") {
        if (email && !emailRegExp.test(email)) {
            return 'Некорректный Email';
        }
        if (this.state.errors.email) {
            return this.state.errors.email;
        }
        return null;
    }

    renderAccaunt() {
        const { addForm: { accaunt: { login, phone, email, name } }, user } = this.props;

        return (
            <div className="kt-wizard-v2__content" data-ktwizard-type="step-content" data-ktwizard-state="current">
                <div className="kt-heading kt-heading--md">Введите детальную информацию</div>
                <div className="kt-form__section kt-form__section--first">
                    <div className="kt-wizard-v2__form">
                        <Input
                            label="Логин"
                            required={true}
                            onChange={this.setFormValue('accaunt', 'login')}
                            value={login}
                            placeholder="Введите логин"
                            error={this.isValidLogin(login)}
                            disabled={true}
                        />

                        <Input
                            label="ФИО"
                            required={true}
                            onChange={this.setFormValue('accaunt', 'name')}
                            value={name}
                            placeholder="Введите ФИО"
                        />
                      
                        <div className="row">
                            <div className="col-xl-6">
                                <Input
                                    label="Телефон"
                                    onChange={this.setFormValue('accaunt', 'phone')}
                                    value={phone}
                                    placeholder="Введите телефон"
                                    disabled={user.role.id === 1}
                                />
                            </div>
                            {
                                user.role.id !== 1 && (
                                    <div className="col-xl-6">
                                        <Input
                                            label="Email"
                                            required={true}
                                            onChange={this.setFormValue('accaunt', 'email')}
                                            value={email}
                                            placeholder="Введите email"
                                            error={this.isValidEmail(email)}
                                            disabled={true}
                                        />
                                    </div>
                                )
                            }
                            
                        </div>

                    </div>
                </div>
            </div>
                        
        );
    }

    renderCity() {
        const { addForm: { city: { value, street, house, room, entrance } }, cities, user } = this.props;

        return (
            <div className="kt-wizard-v2__content" data-ktwizard-type="step-content" data-ktwizard-state="current">
                <div className="kt-heading kt-heading--md">{ user.role.id === 1 ? 'Город и адрес доставки' : 'Город обсуживания'}</div>
                <div className="kt-form__section kt-form__section--first">
                    <div className="kt-wizard-v2__form">
                        <div className="row">
                            <div className="col-xl-6">
                                <div className="form-group">
                                    <label>Город *</label>
                                    <select value={String(value)} onChange={this.setFormValue('city', 'value')} className="form-control">
                                        <option value="">Выберите город</option>
                                        {
                                            cities.map(item => (
                                                <option key={item.id} value={item.id}>{item.name}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                        {
                            user.role.id === 1 && (
                                <div>
                                    <div className="row">
                                        <div className="col-xl-6">
                                            <Input
                                                label="Улица"
                                                onChange={this.setFormValue('city', 'street')}
                                                value={street}
                                                placeholder="Введите улицу"
                                            />
                                        </div>
                                        <div className="col-xl-6">
                                            <Input
                                                label="Дом"
                                                onChange={this.setFormValue('city', 'house')}
                                                value={house}
                                                placeholder="Введите дом"
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xl-6">
                                            <Input
                                                label="Квартира"
                                                onChange={this.setFormValue('city', 'room')}
                                                value={room}
                                                placeholder="Введите квартиру"
                                            />
                                        </div>
                                        <div className="col-xl-6">
                                            <Input
                                                label="Подъезд"
                                                onChange={this.setFormValue('city', 'entrance')}
                                                value={entrance}
                                                placeholder="Введите подъезд"
                                            />
                                        </div>
                                    </div>   
                                </div>
                            )
                        }
                        
                    </div>
                </div>
            </div>
        
        );
    }

    renderAdress(item) {
        return `${item.street}, ${item.house}${item.entrance ? `, подъезд ${item.entrance}` : ''}${item.room ? `, кв./оф. ${item.room}` : ''}`;
    }

    addFilial = (e) => {
        const { addForm } = this.props;
        const data = { ...addForm };
        data.filial.push('');

        this.props.setAddForm(data);
    }

    deleteFilial = (index) => (e) =>{
        const { addForm } = this.props;
        const data = { ...addForm };
        data.filial = data.filial.filter((item, i) => index !== i);
        this.props.setAddForm(data);
    }

    renderFilial() {
        const { addForm: { filial }, filials, user } = this.props;
        const filData = filial.length ? [...filial] : [''];

        return (
            <div className="kt-wizard-v2__content" data-ktwizard-type="step-content" data-ktwizard-state="current">
                <div className="kt-heading kt-heading--md">{user.role.id === 1 ? 'Пункт самовывоза' : 'Настройка филиалов'}</div>
                <div className="kt-form__section kt-form__section--first">
                    <div className="kt-wizard-v2__form">
                        {
                            filData.map((filialId, index) => (
                                <div key={index} className="form-group">
                                    <label>Филиал {index + 1}</label>
                                    <div style={{ display: 'flex' }}>
                                        <select value={filialId} onChange={this.setFormFilial(index)} className="form-control">
                                            <option value="">Выберите филиал</option>
                                            {
                                                filials.map((item, index) => (
                                                    <option key={item.id} value={item.id} >{this.renderAdress(item)}</option>
                                                ))
                                            }
                                        </select>
                                        {
                                            filData.length > 1 && (
                                                <button onClick={this.deleteFilial(index)} style={{ marginLeft: '15px' }} className="btn btn-danger">Удалить</button>
                                            )
                                        }
                                        
                                    </div>
                                </div>
                            ))
                        }
                        {
                            user.role.id !== 1 && (
                                <button disabled={filData.filter(item => !item).length !== 0} onClick={this.addFilial} className="btn btn-primary">Добавить еще</button>
                            )
                        }
                        
                    </div>
                </div>
            </div>
        
        );
    }

    renderDone() {
        const {
            addForm: {
                accaunt: { login, phone, email, name },
                city: { value, street, house, room, entrance },
                filial
            },
            cities,
            filials,
            user
        } = this.props;

        return (
            <div className="kt-wizard-v2__content" data-ktwizard-type="step-content" data-ktwizard-state="current">
                <div className="kt-heading kt-heading--md">Проверка и сохранение</div>
                <div className="kt-form__section kt-form__section--first">
                    <div className="kt-wizard-v2__review">
                        <div className="kt-wizard-v2__review-item">
                            <div className="kt-wizard-v2__review-title">
                                Аккаунт
                            </div>
                            <div className="kt-wizard-v2__review-content">
                                Логин: {login}<br/>
                                ФИО: {name}<br/>
                                Телефон: {phone}<br/>
                                { user.role.id !== 1 && <span>Email: {email}</span> }
                            </div>
                        </div>
                        <div className="kt-wizard-v2__review-item">
                            <div className="kt-wizard-v2__review-title">
                               Город
                            </div>
                            <div className="kt-wizard-v2__review-content">
                                {cities.filter(item => item.id === +value)[0].name}
                            </div>
                        </div>
                        {
                            user.role.id === 1 && (
                                <div className="kt-wizard-v2__review-item">
                                    <div className="kt-wizard-v2__review-title">
                                        Адрес доставки
                                    </div>
                                    <div className="kt-wizard-v2__review-content">
                                        Улица: {street || '-'}<br/>
                                        Дом: {house || '-'}<br/>
                                        Квартира: {room || '-'}<br/>
                                        Подъезд: {entrance || '-'}
                                    </div>
                                </div>
                            )
                        }
                        <div className="kt-wizard-v2__review-item">
                            <div className="kt-wizard-v2__review-title">
                                {user.role.id === 1 ? 'Пункт самовывоза' : 'Филиалы'}
                            </div>
                            <div className="kt-wizard-v2__review-content">
                                {
                                    filial.filter(fil => fil).map((fil, index) => {
                                        return <div key={index}>{this.renderAdress(filials.filter(item => item.id === +fil)[0])}</div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <ErrorContainer field="user" style={{ margin: '30px 0 0' }} hasCloseButton={true}/>
            </div>
        
        );
    }

    renderTabContent() {
        switch(this.state.step) {
            case 0: return this.renderAccaunt();
            case 1: return this.renderCity();
            case 2: return this.renderFilial();
            case 3: return this.renderDone();
            default: return null;
        }
    }

    onBack = (e) => {
        e.preventDefault();
        this.setState({ step: this.state.step - 1 })
    }

    onNext = (e) => {
        e.preventDefault();
        if (this.state.step === 0) {
            this.props.fetchCities();
            this.setState({ step: this.state.step + 1 });
        } else if (this.state.step === 1) {
            this.props.fetchFilials(this.props.addForm.city.value);
            this.setState({ step: this.state.step + 1 });
        } else {
            this.setState({ step: this.state.step + 1 });
        }
    }

    onSave = (e) => {
        e.preventDefault();
        const { addForm, updateUser } = this.props;
        const { accaunt, city, filial } = addForm;
        const user = {};
        user.username = accaunt.login;
        user.name = accaunt.name;
        user.password = accaunt.password;
        user.email = accaunt.email;
        if (accaunt.phone) {
            user.phone = accaunt.phone;
        }

        user.city = city.value;
        user.street = city.street;
        user.house = city.house;
        user.room = city.room;
        user.entrance = city.entrance;
        user.points = filial;
        
        updateUser(this.props.match.params.id, user).then(result => {
            if (result) {
                this.props.history.push(`/users/${this.props.match.params.id}`);
            }
        });
    }

    isValidAccaunt() {
        const { addForm: { accaunt: { login, name } } } = this.props;
        if (!login || !name) {
            return false;
        }

        if (this.isValidLogin(login)) {
            return false;
        }

        return true;
    }

    isValidCity() {
        if (!this.props.addForm.city.value) {
            return false
        }
        return true;
    }

    isValidFilial() {
        if (this.props.addForm.filial.length === 0 || this.props.addForm.filial.filter(item => item).length === 0) {
            return false
        }
        return true;
    }

    isValidNext() {
        switch(this.state.step) {
            case 0: return this.isValidAccaunt();
            case 1: return this.isValidCity();
            case 2: return this.isValidFilial();
            default: return true;
        }
    }

    isValidMenuButton(step) {
        switch(step) {
            case 0: return true;
            case 1: return this.isValidAccaunt();
            case 2: return this.isValidAccaunt() && this.isValidCity();
            case 3: return this.isValidAccaunt() && this.isValidCity() && this.isValidFilial();
            default: return true;
        }
    }

    renderLoader() {
        return (
            <div data-ktwizard-type="action-next">
                <div style={{ marginRight: '25px' }} className="kt-spinner kt-spinner--lg kt-spinner--info"></div>
            </div>
        );
    }

    renderAction() {
        return (
            <div className="kt-form__actions">
                <button onClick={this.onBack} className="btn btn-secondary btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u" data-ktwizard-type="action-prev">
                    Назад
                </button>
                {
                    this.props.loading ? this.renderLoader() : (
                        <button onClick={this.onSave} className="btn btn-success btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u" data-ktwizard-type="action-submit">
                            Сохранить
                        </button>
                    )
                }
                
                {
                    this.state.loader ? this.renderLoader() : (
                        <button disabled={!this.isValidNext()} onClick={this.onNext} className="btn btn-brand btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u" data-ktwizard-type="action-next">
                            Следущий шаг
                        </button>
                    )
                }
                
            </div>
        );
    }

    preventDefault = (e) => {
        e.preventDefault();
    }

    renderTabs() {
        const { user } = this.props;
        return (
            <div
            className="kt-grid  kt-wizard-v2 kt-wizard-v2--white" data-ktwizard-state={this.state.step === 0 ? 'first' : this.state.step === 3 ? 'last' : 'between'}>
                <div className="kt-grid__item kt-wizard-v2__aside">
                    <div className="kt-wizard-v2__nav">
                        <div className="kt-wizard-v2__nav-items">
                            {
                                tabs.map((tab, index) => (
                                    <a
                                        key={index}
                                        onClick={this.isValidMenuButton(index) ? this.onTab(index) : this.preventDefault}
                                        className={cn({ 'kt-wizard-v2__nav-item': true, disabled: !this.isValidMenuButton(index) })}
                                        href="/"
                                        data-ktwizard-type="step"
                                        data-ktwizard-state={this.state.step === index ? 'current' : 'pending'}>
                                        <div className="kt-wizard-v2__nav-body">
                                            <div className="kt-wizard-v2__nav-icon">
                                                <i className={tab.icon}></i>
                                            </div>
                                            <div className="kt-wizard-v2__nav-label">
                                                <div className="kt-wizard-v2__nav-label-title">
                                                    {user.role.id === 1 ? tab.titleUser : tab.title}
                                                </div>
                                                <div className="kt-wizard-v2__nav-label-desc">
                                                    {user.role.id === 1 ? tab.subTitleUser : tab.subTitle}
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                ))
                            }
                        </div>
                    </div>
                </div>
                <div className="kt-grid__item kt-grid__item--fluid kt-wizard-v2__wrapper">
                    <form onSubmit={(e) => e.preventDefault()} className="kt-form" noValidate="noValidate">
                        {this.renderTabContent(this.state.step)} 
                        {this.renderAction()}
                    </form>
                </div>
            </div>
    
        );
    }

    render() {
        if (!this.props.user) return null;
        return (
            <Fragment>
                {this.renderSubHeader()}
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div className="kt-portlet">
                    <div className="kt-portlet__body kt-portlet__body--fit">
                       {this.renderTabs()}
                    </div>
                </div>
	        </div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    addForm: state.users.add || {},
    loading: state.loaders.register,
    loadingCity: state.loaders.cities,
    cities: state.handbook.cities,
    filials: state.handbook.filials,
    user: state.users.detail
});

const mapDispatchToProps = {
    setAddForm: (data) => setAddForm(data),
    updateUser: (id, params) => updateUser(id, params),
    fetchCities: () => fetchCities(),
    fetchFilials: (cityId) => fetchFilials(cityId),
    fetchUser: (id) => fetchUser(id)
}

export default connect(mapStateToProps, mapDispatchToProps)(EditUser);