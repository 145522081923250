import React, { Component } from 'react';
import ActionMenu from '../utils/ActionMenu';
import { withRouter  } from 'react-router-dom';
class TableFilials extends Component {
    renderThead() {
        return (
            <thead className="kt-datatable__head">
                <tr className="kt-datatable__row">
                    <th className="kt-datatable__cell">
                        <span style={{ width: '100px' }}>Город</span>
                    </th>

                    <th className="kt-datatable__cell">
                        <span style={{ width: '100px' }}>Улица</span>
                    </th>

                    <th className="kt-datatable__cell">
                        <span style={{ width: '50px' }}>Дом</span>
                    </th>
                    <th className="kt-datatable__cell">
                        <span style={{ width: '70px' }}>Квартира</span>
                    </th>
                    <th className="kt-datatable__cell">
                        <span style={{ width: '70px' }}>Подъезд</span>
                    </th>
                    <th className="kt-datatable__cell">
                        <span style={{ width: '150px' }}>Email</span>
                    </th>
                    <th className="kt-datatable__cell">
                        <span style={{ width: '150px' }}>Телефон</span>
                    </th>
                    <th className="kt-datatable__cell">
                        <span style={{ width: '80px' }}>Действия</span>
                    </th>
                </tr>
            </thead>
        );
    }
    
    handleDoubleClick = (item) => () => {
        this.props.history.push(`/handbook/filials/${+item.id}`)
    }

    renderAdress(item) {
        return `${item.street}, ${item.house}${item.entrance ? `, подъезд ${item.entrance}` : ''}${item.room ? `, кв./оф. ${item.room}` : ''}`;
    }
    
    renderBody() {
        
        return (
            <tbody className="kt-datatable__body">
                {
                    this.props.items.map(item => {
                        const actions = [
                            { name: 'Просмотр', icon: 'flaticon2-expand', action: () => this.props.history.push(`/handbook/filials/${+item.id}`) },
                            { access: 4, name: 'Редактирование', icon: 'flaticon2-contract', action: () => this.props.history.push(`/handbook/filials/edit/${+item.id}`) },
                            { access: 4, name: 'Удалить', icon: 'flaticon2-trash', action: () => this.props.delete(item) }
                        ].filter(item => !item.access || item.access === this.props.role);;
                        return (
                            <tr onDoubleClick={this.handleDoubleClick(item)} key={item.id} className="kt-datatable__row">
                                <td className="kt-datatable__cell">
                                    <span style={{ width: '100px' }}>
                                        {item.city.name}
                                    </span>
                                </td>
                            <td className="kt-datatable__cell">
                                <span style={{ width: '100px' }}>
                                    { item.street }
                                </span>
                            </td>
                            <td className="kt-datatable__cell">
                                <span style={{ width: '50px' }}>
                                    { item.house }
                                </span>
                            </td>
                            <td className="kt-datatable__cell">
                                <span style={{ width: '70px' }}>
                                    { item.room ? item.room : '-' }
                                </span>
                            </td>
                            <td className="kt-datatable__cell">
                                <span style={{ width: '70px' }}>
                                    { item.entrance ? item.entrance : '-' }
                                </span>
                            </td>
                            <td className="kt-datatable__cell">
                                <span style={{ width: '150px' }}>
                                    { item.email }
                                </span>
                            </td>
                            <td className="kt-datatable__cell">
                                <span style={{ width: '150px' }}>
                                    { item.phone }     
                                </span>
                            </td>
                            <td className="kt-datatable__cell">
                                <ActionMenu actions={actions}/>
                            </td>
                        </tr>
                        )
                    }) 
                }
            </tbody>
        );
    }

    render() {
        if (this.props.items.length === 0) {
            return (
                <div>Филиалов не найдено</div>
            );
        }
        return (
            <table className="kt-datatable__table" style={{ minHeight: '500px' }}>
                {this.renderThead()}
                {this.renderBody()}
            </table>
        );
    }
}

export default withRouter(TableFilials);