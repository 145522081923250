import React, { Component } from 'react'
import { connect } from 'react-redux'
import Switch from '../../components/utils/Switch';
import Loader from '../../components/utils/Loader';
import { fetchSettings, setEditedSettings, updateSettings } from '../../action/app';
import { isEqual } from 'lodash'; 
import ErrorContainer from '../../components/utils/ErrorContainer';

const styles = {
    loading: {
        minHeight: '500px',
        display: 'flex',
        justifyContent: 'center'
    }
}

export class Settings extends Component {
    componentDidMount() {
        this.props.fetchSettings();
    }

    onChangeSettings = (field) => (value) => {
        this.props.setEditedSettings(field, value);
    }
    
    onSave = (e) => {
        e.preventDefault();
        this.props.updateSettings(this.props.settings.edited);
    }

    isValidSave() {
        const { edited, init } = this.props.settings;
        
        let result = true;

        if (isEqual(edited, init)) {
            result = false;
        }

        return result;
    }

    render() {
        const { edited } = this.props.settings;

        if (this.props.loading) {
            return (
                <div style={styles.loading}><Loader /></div>
            );
        }

        return (
            <div className="kt-grid__item kt-grid__item--fluid    kt-portlet    kt-inbox__list kt-inbox__list--shown" id="kt_inbox_list" style={{ display: 'flex' }}>
                <div className="kt-portlet__head">
                    <div className="kt-inbox__toolbar kt-inbox__toolbar--extended">
                        <div className="kt-inbox__actions kt-inbox__actions--expanded">
                            <div className="kt-inbox__check">
                            <h5 style={{ marginBottom: 0 }}>Настройки</h5>
                            </div>
                        </div>
                        <div className="kt-inbox__controls">
                            <button onClick={this.onSave} disabled={!this.isValidSave()} type="button" className="btn btn-primary">Сохранить</button>
                        </div>
                    </div>
                </div>
                <div className="kt-portlet__body kt-portlet__body--fit">    
                    <div className="kt-notification">
                        <div className="kt-notification__item">
                            <div className="kt-notification__item-details">
                                <div className="kt-notification__item-title">
                                    Отправлять сообщение при начислении бонусов
                                </div>
                            </div>
                            <div style={{ paddingTop: 5 }}>
                                <Switch
                                    nomargin={true}
                                    checked={edited.pushMessageBonus}
                                    onChange={(value) => this.onChangeSettings('pushMessageBonus')(value)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <ErrorContainer field="settings" container={true} hasCloseButton={true}/>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
    loading: state.loaders.settings
});

const mapDispatchToProps = {
    fetchSettings: () => fetchSettings(),
    setEditedSettings: (field, value) => setEditedSettings(field, value),
    updateSettings: (data) => updateSettings(data)
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings)
