import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import { fetchFilial } from '../../action/handbook';
import { Link } from 'react-router-dom';
import { YMaps, Map, Placemark } from 'react-yandex-maps';
import Loader from '../utils/Loader';
const styles = {
    loading: {
        minHeight: '500px',
        display: 'flex',
        justifyContent: 'center'
    }
}

class DetailFilial extends Component {
    componentDidMount() {
        this.props.fetchFilial(this.props.match.params.id)
    }

    renderMap = () => {
        return (
            <YMaps
                style={{ padding: '25px ', marginTop: -30 }}
                query={{
                    apikey: '71140458-f614-4101-ad58-f75c79f0f6fe'
                }}>
                <Map
                    instanceRef={ref => {
                        if (ref) {
                            ref.behaviors.disable('drag');
                            ref.behaviors.disable('scrollZoom');
                            
                        } 
                    }}
                    width="100%"
                    height="100%"
                    defaultState={{
                        center: this.props.filial.coord.split(','),
                        zoom: 15
                    }}
                >
                    <Placemark geometry={this.props.filial.coord.split(',')} />
                </Map>
            </YMaps>
        );
    }

    renderSubHeader() {
        return (
            <div className="kt-subheader kt-grid__item">
                <div className="kt-container  kt-container--fluid ">
                    <div className="kt-subheader__main">
                        <h3 className="kt-subheader__title">{this.props.filial.city.name}</h3>
                        <span className="kt-subheader__separator kt-subheader__separator--v"></span>
                        <div className="kt-subheader__group" id="kt_subheader_search">
                            <span className="kt-subheader__desc" id="kt_subheader_total">
                                
                            </span>
                        </div>
                    </div>
                    <div className="kt-subheader__toolbar">
                        <button
                            onClick={() => this.props.history.push('/handbook/filials')}
                            className="btn btn-default btn-bold">
                            Назад
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { filial } = this.props;
   
        if (this.props.loading || !filial.id) {
            return (
                <div style={styles.loading}><Loader/></div>
            );
        }
        
        return (
            <Fragment>
                {this.renderSubHeader()}
                <div className="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="kt-portlet">
                                <div className="kt-portlet__head">
                                    <div className="kt-portlet__head-label">
                                        <h3 className="kt-portlet__head-title">
                                           Детальная информация
                                        </h3>
                                    </div>
                                    <div className="kt-portlet__head-toolbar">
                                        <i className="flaticon2-gear"></i>
                                    </div>
                                </div>
                                <div className="kt-form kt-form--label-right">
                                    <div className="kt-portlet__body">
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="form-group form-group-xs row">
                                                    <label className="col-6 col-form-label">Город:</label>
                                                    <div className="col-6">
                                                        <span className="form-control-plaintext kt-font-bolder">{filial.city.name}</span>
                                                    </div>
                                                </div>
                                                <div className="form-group form-group-xs row">
                                                    <label className="col-6 col-form-label">Улица:</label>
                                                    <div className="col-6">
                                                        <span className="form-control-plaintext kt-font-bolder">{filial.street}</span>
                                                    </div>
                                                </div>
                                                <div className="form-group form-group-xs row">
                                                    <label className="col-6 col-form-label">Дом:</label>
                                                    <div className="col-6">
                                                        <span className="form-control-plaintext">{filial.house || '---'}</span>
                                                    </div>
                                                </div>
                                                <div className="form-group form-group-xs row">
                                                    <label className="col-6 col-form-label">Офис:</label>
                                                    <div className="col-6">
                                                        <span className="form-control-plaintext kt-font-bolder">{filial.room || '---'}</span>
                                                    </div>
                                                </div>
                                                <div className="form-group form-group-xs row">
                                                    <label className="col-6 col-form-label">Подъезд:</label>
                                                    <div className="col-6">
                                                        <span className="form-control-plaintext kt-font-bolder">{filial.entrance || '---'}</span>
                                                    </div>
                                                </div>
                                                <div className="form-group form-group-xs row">
                                                    <label className="col-6 col-form-label">Email:</label>
                                                    <div className="col-6">
                                                        <span className="form-control-plaintext kt-font-bolder">{filial.email || '---'}</span>
                                                    </div>
                                                </div>
                                                <div className="form-group form-group-xs row">
                                                    <label className="col-6 col-form-label">Телефон:</label>
                                                    <div className="col-6">
                                                        <span className="form-control-plaintext kt-font-bolder">{filial.phone || '---'}</span>
                                                    </div>
                                                </div>
                                                <div className="form-group form-group-xs row">
                                                    <label className="col-6 col-form-label">Время работы:</label>
                                                    <div className="col-6">
                                                        <span className="form-control-plaintext kt-font-bolder">{filial.timeWork || '---'}</span>
                                                    </div>
                                                </div>
                                                <div className="form-group form-group-xs row">
                                                    <label className="col-6 col-form-label">Координаты:</label>
                                                    <div className="col-6">
                                                        <span className="form-control-plaintext kt-font-bolder">{filial.coord}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                {this.renderMap()}
                                            </div>
                                            </div>
                                           
                                        </div>
                                    {
                                        this.props.role.id === 4 && (
                                            <div className="kt-portlet__foot">
                                                <div className="kt-form__actions kt-space-between">
                                                    <Link to={`/handbook/filials/edit/${filial.id}`} className="btn btn-label-brand btn-sm btn-bold">Редактировать</Link>
                                                </div>
                                            </div>
                                        )
                                    }
                                    

                                </div>
                            </div>
                        </div> 
                    </div>
                </div>

            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    filial: state.handbook.filial.detail,
    loading: state.loaders.filial,
    role: state.user.info.role
});

const mapDispatchToProps = {
    fetchFilial: (id) => fetchFilial(id)
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailFilial);