import { urls } from '../constants/app';
import request from './utils';
import { setError, resetError } from './errors';

export function fetchSettings() {
    return dispatch => {
        dispatch({ type: 'SET_LOADER', field: 'settings', value: true });

        return request({ method: 'get', url: `${urls.settings}` })
            .then((response) => {
                dispatch({ type: 'SET_SETTINGS', data: response.length ? response[0] : {}  });
                dispatch({ type: 'SET_LOADER', field: 'settings', value: false });
                return response;
            })
            .catch(error => {
                dispatch(setError(error, 'settings'));
                dispatch({ type: 'SET_LOADER', field: 'settings', value: false });
                return null
            });
        
    }
}

export function updateSettings(data) {
    return dispatch => {
        dispatch({ type: 'SET_LOADER', field: 'settings', value: true });
        dispatch(resetError('settings'));
        return request({ method: 'put', url: `${urls.settings}/1`, data })
            .then((response) => {
                dispatch({ type: 'SET_SETTINGS', data: response ? response : {}  });
                dispatch({ type: 'SET_LOADER', field: 'settings', value: false });
                return response;
            })
            .catch(error => {
                dispatch(setError({ status: 508 }, 'settings'));
                dispatch({ type: 'SET_LOADER', field: 'settings', value: false });
                return null
            });
        
    }
}

export function setEditedSettings(field, value) {
    return dispatch => {
        dispatch({ type: 'SET_EDITED_SETTINGS', field, value });
    }
}


export function fetchPromocodes() {
    return (dispatch, getState) => {
    dispatch({ type: 'SET_LOADER', field: 'promocodes', value: true });

    const paging = getState().promocodes.paging;
    const sorting = getState().promocodes.sorting;

    const params = {};

    params['_start'] = paging.start;
    params['_limit'] = paging.limit;

    params['_sort'] = `${sorting.field}:${sorting.type}`;

    request({ method: 'get', url:  urls.promocodes+'/count' })
        .then(result => {
            dispatch({ type: 'SET_PROMOCODES_COUNT', count: result });
            return;
        }).then(() => {
            return request({ method: 'get', url: urls.promocodes, params }).then((response) => {
                dispatch({ type: 'SET_PROMOCODES', promocodes: response });
                dispatch({ type: 'SET_LOADER', field: 'promocodes', value: false });
            });
        })
        .catch(error => {
            dispatch(setError(error, 'promocodes'));
            dispatch({ type: 'SET_LOADER', field: 'promocodes', value: false });
        });
    }
}

export function setEditedPromocode(promocode) {
    return { type: 'SET_EDITED_PROMOCODE', promocode }
}

export function setDeletePromocode(promocode) {
    return { type: 'SET_DELETE_PROMOCODE', promocode }
}


export function updatePromocode(id, data) {
    return dispatch => {
        dispatch({ type: 'SET_LOADER', field: 'promocodes', value: true });
        dispatch(resetError('promocodes'));
        return request({ method: 'put', url: `${urls.promocodes}/${id}`, data })
            .then((response) => {
                dispatch({ type: 'SET_LOADER', field: 'promocodes', value: false });
                return response;
            })
            .catch(error => {
                dispatch(setError({ status: 508 }, 'promocodes'));
                dispatch({ type: 'SET_LOADER', field: 'promocodes', value: false });
                return null
            });
        
    }
}

export function setAddPromocode(promocode) {
    return { type: 'SET_ADD_PROMOCODE', promocode }
}

export function addPromocode(data) {
    return dispatch => {
        dispatch({ type: 'SET_LOADER', field: 'promocodes', value: true });
        dispatch(resetError('promocodes'));
        return request({ method: 'post', url: `${urls.promocodes}123`, data })
            .then((response) => {
                dispatch({ type: 'SET_LOADER', field: 'promocodes', value: false });
                return response;
            })
            .catch(error => {
                dispatch(setError({ status: 506 }, 'promocodes'));
                dispatch({ type: 'SET_LOADER', field: 'promocodes', value: false });
                return null
            });
        
    }
}

export function deletePromocode(id) {
    return dispatch => {
        dispatch({ type: 'SET_LOADER', field: 'promocodes', value: true });
        dispatch(resetError('promocodes'));
        return request({ method: 'delete', url: `${urls.promocodes}/${id}` })
            .then((response) => {
                dispatch({ type: 'SET_LOADER', field: 'promocodes', value: false });
                return response;
            })
            .catch(error => {
                dispatch(setError({ status: 507 }, 'promocodes'));
                dispatch({ type: 'SET_LOADER', field: 'promocodes', value: false });
                return null
            });
        
    }
}
