import axios from 'axios';
import { getCookie } from '../utils/cookie';
import { baseUrl } from '../constants/app';

const request = function(options) {
    const client = axios.create({
        baseURL: baseUrl,
        headers: {
          Authorization: `Bearer ${getCookie('auth')}`
        }
    });

    const onSuccess = function(response) {
        return response.data;
    }

    const onError = function(error) {
        return Promise.reject(error.response || error.message);
    }

    return client(options).then(onSuccess).catch(onError);
}

export default request;