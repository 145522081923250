import { combineReducers } from 'redux';
import user from './user';
import loaders from './loaders';
import errors from './errors';
import users from './users';
import handbook from './handbook';
import orders from './orders';
import goods from './goods';
import settings from './settings';
import promocodes from './promocodes';
import messages from './messages';

const rootReducer = combineReducers({
  user,
  users,
  loaders,
  errors,
  handbook,
  messages,
  orders,
  goods,
  settings,
  promocodes
});

export default rootReducer;