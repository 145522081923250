import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { fetchFilials, setHandbookPaging, deleteFilial } from '../../action/handbook';
import { setError } from '../../action/errors';
import Loader from '../utils/Loader';
import TableFilials from './TableFilials';
import Portlet from '../utils/Portlet';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import swal from 'sweetalert';
import ErrorContainer from '../utils/ErrorContainer';

const styles = {
    loading: {
        minHeight: '500px',
        display: 'flex',
        justifyContent: 'center'
    }
}

class FilialList extends Component {
    componentDidMount() {
        this.props.fetchFilials();
    }

    state = {
        currentPage: 0
    }

    setPageLimit = (e) => {
        e.preventDefault();
        const updatedPaging = { start: 0, limit: e.target.value};
        this.setState({ currentPage: 0 });
        this.props.setHandbookPaging('filial', updatedPaging);
        this.props.fetchFilials();
    }

    handlePageClick = data => {
        const { paging } = this.props;
        this.setState({ currentPage: data.selected }, () => {
            let nextStart =  data.selected * paging.limit;
            const updatedPaging = {...paging, start: nextStart};
            this.props.setHandbookPaging('filial', updatedPaging);
            this.props.fetchFilials();
        });
    };

    renderPagination() {
        const { count, paging } = this.props;
        const pages = Math.ceil(+count / +paging.limit);

        return (
            <div className="kt-datatable__pager kt-datatable--paging-loaded">
                <ReactPaginate
                    previousLabel={<i className="flaticon2-back"></i>}
                    nextLabel={<i className="flaticon2-next"></i>}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={pages}
                    marginPagesDisplayed={8}
                    pageRangeDisplayed={5}
                    forcePage={this.state.currentPage}
                    onPageChange={this.handlePageClick}
                    containerClassName={'kt-datatable__pager-nav'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                    pageLinkClassName="kt-datatable__pager-link kt-datatable__pager-link-number"
                    previousLinkClassName="kt-datatable__pager-link kt-datatable__pager-link--prev"
                    nextLinkClassName="kt-datatable__pager-link kt-datatable__pager-link--next"
                    activeLinkClassName="kt-datatable__pager-link--active"
                    disabledClassName="kt-datatable__pager-link--disabled"
                />

                    <div className="kt-datatable__pager-info">
                        <div className="kt-datatable__pager-size">
                            <select onChange={this.setPageLimit} value={paging.limit} className="form-control">
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                            
                        </div>
                        {
                            paging.start + paging.limit >= count ? (
                                <span className="kt-datatable__pager-detail">Показано {paging.start + 1} - {+count} из {count}</span>
                            ) : (
                                <span className="kt-datatable__pager-detail">Показано {paging.start + 1} - {+paging.start + +paging.limit} из {count}</span>
                            )
                        }
                       
                    </div>
            </div>
    
        );
    }

    renderSubHeader() {
        return (
            <div className="kt-subheader kt-grid__item">
                <div className="kt-container  kt-container--fluid ">
                <div className="kt-subheader__main">
                    <h3 className="kt-subheader__title">Филиалы</h3>
                    <span className="kt-subheader__separator kt-subheader__separator--v"></span>
                    <div className="kt-subheader__group" id="kt_subheader_search">
                        <span className="kt-subheader__desc" id="kt_subheader_total">
                            {this.props.count} Всего
                        </span>
                       
                    </div>
                </div> 
                {
                    this.props.role === 4 && (
                        <div className="kt-subheader__toolbar">
                            <Link to="/handbook/filials/add" className="btn btn-label-brand btn-bold">
                                Добавить филиал
                            </Link>
                        </div>
                    )
                }
                
            </div>
            </div>
        );
    }

    deleteFilial = (item) => {
        swal({
            title: "Удаление филиала",
            text: "Вы действительно хотите удалить филиал?",
            icon: "warning",
            dangerMode: true,
            buttons: ["Отмена", "Удалить"],
        }).then((willDelete) => {
            if (willDelete) {
                deleteFilial(item).then(result => {
                    if (result) {
                        this.props.fetchFilials();
                    } else {
                        this.props.setError({ status: 507 }, 'filials');
                    }
                })
            }
        });
        
    }

    render() {
        if (this.props.loading) {
            return (
                <div style={styles.loading}><Loader /></div>
            );
        }
        
        return (
            <Fragment>
                { this.renderSubHeader() }
                <div className="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <Portlet fit={true}>
                    <ErrorContainer field="filials" style={{ margin: '15px' }} hasCloseButton={true}/>
                    <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded">
                        <TableFilials delete={this.deleteFilial} items={this.props.filials} role={this.props.role}/>
                        {
                            this.props.filials.length > 0 && this.renderPagination()
                        }
                    </div>
                </Portlet>
            </div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    filials: state.handbook.filials,
    loading: state.loaders.filials,
    paging: state.handbook.paging.filial,
    count: state.handbook.count.filials,
    role: state.user.info.role.id
});

const mapDispatchToProps = {
    fetchFilials: () => fetchFilials(),
    setHandbookPaging: (field, paging) => setHandbookPaging(field, paging),
    setError: (code, field) => setError(code, field)
}

export default connect(mapStateToProps, mapDispatchToProps)(FilialList);