import axios from 'axios';
import { urls } from '../constants/app';
import { setCookie } from '../utils/cookie';

export function singIn(login, pass, remember) {
    return (dispatch) => {
        dispatch({ type: 'SET_LOADER', field: 'singIn', value: true });
        dispatch({ type: 'SET_ERROR', field: 'singIn', value: null });

        return axios
            .post(urls.singIn, {
                identifier: login,
                password: pass,
                rememberMe: remember
            })
            .then(response => {
                if (response.data.user.role.id === 1) {
                    dispatch({ type: 'SET_ERROR', field: 'singIn', value: 'Доступ запрещен!' });
                    dispatch({ type: 'SET_LOADER', field: 'singIn', value: false });
                    return false;
                } else {
                    if (remember) {
                        setCookie('auth', response.data.jwt, { 'max-age': 86400 });
                    } else {
                        setCookie('auth', response.data.jwt );
                    }
                    dispatch({ type: 'SET_USER_INFO', user: response.data.user });
                    dispatch({ type: 'SET_LOADER', field: 'singIn', value: false });
                    return true;
                }
                
            })
            .catch(error => {
                dispatch({ type: 'SET_ERROR', field: 'singIn', value: 'Неправильный логин или пароль' });
                dispatch({ type: 'SET_LOADER', field: 'singIn', value: false });
            });
        }
}

