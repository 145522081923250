import React, { Component, Fragment } from 'react';
import { withRouter  } from 'react-router-dom';
import Switch from '../utils/Switch';
import Input from '../utils/Input';
import { isEqual } from 'lodash';
import cn from 'classnames';

const deliveryType = {
    all: 'Любая',
    own: 'Самовывоз',
    delivery: 'Доставка'
}


class List extends Component {
    componentWillUnmount() {
        this.props.setEditedPromocode(null);
        this.props.setDeletePromocode(null);
        this.props.setAddPromocode(null);
    }

    renderThead() {
        return (
            <thead className="kt-datatable__head">
                <tr className="kt-datatable__row">
                    <th className="kt-datatable__cell">
                        <span style={{ width: '150px' }}>
                            Промокод
                        </span>
                    </th>
                    <th className="kt-datatable__cell">
                        <span style={{ width: '100px' }}>Активный</span>
                    </th>
                    <th className="kt-datatable__cell">
                        <span style={{ width: '100px' }}>Значение</span>
                    </th>
                    <th className="kt-datatable__cell">
                        <span style={{ width: '123px' }}>Процент</span>
                    </th>
                    <th className="kt-datatable__cell">
                        <span style={{ width: '100px' }}>Сумма заказа</span>
                    </th>
                    <th className="kt-datatable__cell">
                        <span style={{ width: '123px' }}>Тип доставки</span>
                    </th>
                    <th className="kt-datatable__cell">
                        <span style={{ width: '220px' }}>Действия</span>
                    </th>
                </tr>
            </thead>
        );
    }
    
    handleDoubleClick = (item) => () => {
        this.props.history.push(`/promocodes/edit/${+item.id}`);
    }
    
    setEditedPromocode = (promocode) => (e) => {
        this.props.setEditedPromocode(promocode);
    }

    setDeletePromocode = (promocode) => (e) => {
        this.props.setDeletePromocode(promocode);
    }

    cancelEdit = () => {
        this.props.setEditedPromocode(null);
    }

    cancelAdd = () => {
        this.props.setAddPromocode(null);
    }

    isValidSaveEdit(newItem, oldItem) {
        let result = true;
        if (isEqual(newItem, oldItem)) result = false;
        if (!newItem.name || !newItem.value) {
            result = false;
        }
        return result;
    }

    isValidAdd() {
        let result = true;
       
        if (!this.props.add.name || !this.props.add.value) {
            result = false;
        }
        return result;
    }

    onChangeEdit = (field, value) => {
        const updated = {
            ...this.props.edited,
            [field]: value
        };
        this.props.setEditedPromocode(updated);
    }

    onChangeAdd = (field, value) => {
        const updated = {
            ...this.props.add,
            [field]: value
        };
        this.props.setAddPromocode(updated);
    }

    updatePromocode = () => {
        this.props.updatePromocode(this.props.edited.id, this.props.edited).then(result => {
            if (result) {
                this.props.fetchPromocodes();
                this.props.setEditedPromocode(null);
            }
        });
    }

    addPromocode = () => {
        this.props.addPromocode(this.props.add).then(result => {
            if (result) {
                this.props.fetchPromocodes();
                this.props.setAddPromocode(null);
            }
        });
    }

    deletePromocode = () => {
        this.props.deletePromocode(this.props.deleted.id).then(result => {
            if (result) {
                this.props.fetchPromocodes();
                this.props.setDeletePromocode(null);
            }
        });
    }

    renderEditRow(item, oldItem) {
        return (
            <tr onDoubleClick={this.handleDoubleClick(item)} key={item.id} className="kt-datatable__row active">
                <td className="kt-datatable__cell">
                    <span style={{ width: '150px' }}>
                        <Input onChange={(e) => this.onChangeEdit('name', e.target.value)} disableGroup={true} value={item.name}/>
                        
                    </span>
                </td>
                <td className="kt-datatable__cell">
                    <span style={{ width: '100px' }}>
                        <Switch nomargin={true} onChange={(value) => this.onChangeEdit('active', +value)} checked={!!item.active}/>
                    </span>
                </td>
                <td className="kt-datatable__cell">
                    <span style={{ width: '100px' }}>
                        <Input onChange={(e) => this.onChangeEdit('value', +e.target.value)} type="number" disableGroup={true} value={item.value}/>
                    </span>
                </td>

                <td className="kt-datatable__cell">
                    <span style={{ width: '123px' }}>
                        <Switch nomargin={true} onChange={(value) => this.onChangeEdit('persent', +value)} checked={!!item.persent}/>
                    </span>
                </td>
                <td className="kt-datatable__cell">
                    <span style={{ width: '100px' }}>
                        <Input onChange={(e) => this.onChangeEdit('minPrice', +e.target.value)} type="number" disableGroup={true} value={item.minPrice}/>
                    </span>
                </td>
                <td className="kt-datatable__cell">
                    <span style={{ width: '123px' }}>
                        <select onChange={(e) => this.onChangeEdit('delivery', e.target.value)} value={item.delivery} className="form-control">
                            {
                                Object.keys(deliveryType).map((key, index) => (
                                    <option key={key} value={key}>{deliveryType[key]}</option>
                                ))
                            }
                        </select>
                    </span>
                </td>
            
                <td className="kt-datatable__cell">
                    <span style={{ width: '220px' }}>
                        <button style={{ marginRight: '4px' }} onClick={this.updatePromocode} disabled={!this.isValidSaveEdit(item, oldItem)} className="btn btn-primary">Сохранить</button>
                        <button onClick={this.cancelEdit} type="button" className="btn btn-secondary">Отменить</button>
                    </span>
                </td>
            </tr>
        );
    }

    renderBody() {

        return (
            <tbody className="kt-datatable__body">
                {this.props.add && this.renderAddRow() }
                {
                    this.props.items.map(item => {
                        if (this.props.edited && this.props.edited.id === item.id) {
                            return this.renderEditRow(this.props.edited, item);
                        }
                        return (
                            <tr onDoubleClick={this.handleDoubleClick(item)} key={item.id} className={cn({ 'kt-datatable__row': true, 'delete': this.props.deleted && this.props.deleted.id === item.id })}>
                                <td className="kt-datatable__cell">
                                    <span style={{ width: '150px' }}>{item.name}</span>
                                </td>
                                <td className="kt-datatable__cell">
                                    <span style={{ width: '100px' }}>{item.active ? 'Да' : 'Нет'}</span>
                                </td>
                                <td className="kt-datatable__cell">
                                    <span style={{ width: '100px' }}>{item.value}</span>
                                </td>

                                <td className="kt-datatable__cell">
                                    <span style={{ width: '123px' }}>{item.persent ? 'Да' : 'Нет'}</span>
                                </td>
                                <td className="kt-datatable__cell">
                                    <span style={{ width: '100px' }}>{item.minPrice}</span>
                                </td>
                                <td className="kt-datatable__cell">
                                    <span style={{ width: '123px' }}>{deliveryType[item.delivery]}</span>
                                </td>
                            
                                <td className="kt-datatable__cell">
                                    <span style={{ width: '220px' }}>
                                        {
                                            this.props.deleted && this.props.deleted.id === item.id ? (
                                                <Fragment>
                                                    <button style={{ marginRight: '4px' }} onClick={this.deletePromocode} className="btn btn-danger">Удалить</button>
                                                    <button onClick={this.setDeletePromocode(null)} type="button" className="btn btn-secondary">Отменить</button>
                                                </Fragment>
                                            ) : (
                                                <Fragment>
                                                    <button style={{ marginRight: '4px' }} disabled={this.props.add} onClick={this.setEditedPromocode(item)} className="btn btn-secondary">Редактировать</button>
                                                    <button disabled={this.props.add} onClick={this.setDeletePromocode(item)} type="button" className="btn btn-danger">Удалить</button>
                                                </Fragment>
                                            )
                                        }
                                       
                                    </span>
                                </td>
                            </tr>
                        );
                    }) 
                }
            </tbody>
        );
    }

    renderAddRow() {
        const { add } = this.props;

        return (
           
                <tr className="kt-datatable__row add">
                    <td className="kt-datatable__cell">
                        <span style={{ width: '150px' }}>
                            <Input onChange={(e) => this.onChangeAdd('name', e.target.value)} disableGroup={true} value={add.name}/>
                        </span>
                    </td>
                    <td className="kt-datatable__cell">
                        <span style={{ width: '100px' }}>
                            <Switch nomargin={true} onChange={(value) => this.onChangeAdd('active', +value)} checked={!!add.active}/>
                        </span>
                    </td>
                    <td className="kt-datatable__cell">
                        <span style={{ width: '100px' }}>
                            <Input onChange={(e) => this.onChangeAdd('value', +e.target.value)} type="number" disableGroup={true} value={add.value}/>
                        </span>
                    </td>
    
                    <td className="kt-datatable__cell">
                        <span style={{ width: '123px' }}>
                            <Switch nomargin={true} onChange={(value) => this.onChangeAdd('persent', +value)} checked={!!add.persent}/>
                        </span>
                    </td>
                    <td className="kt-datatable__cell">
                        <span style={{ width: '100px' }}>
                            <Input onChange={(e) => this.onChangeAdd('minPrice', +e.target.value)} type="number" disableGroup={true} value={add.minPrice}/>
                        </span>
                    </td>
                    <td className="kt-datatable__cell">
                        <span style={{ width: '123px' }}>
                            <select onChange={(e) => this.onChangeAdd('delivery', e.target.value)} value={add.delivery} className="form-control">
                                {
                                    Object.keys(deliveryType).map((key, index) => (
                                        <option key={key} value={key}>{deliveryType[key]}</option>
                                    ))
                                }
                            </select>
                        </span>
                    </td>
                
                    <td className="kt-datatable__cell">
                        <span style={{ width: '220px' }}>
                            
                                <button onClick={this.addPromocode} style={{ marginRight: '4px' }} disabled={!this.isValidAdd()} className="btn btn-primary">Добавить</button>
                                <button onClick={this.cancelAdd} type="button" className="btn btn-secondary">Отменить</button>
                           
                        </span>
                    </td>
                </tr>
            
        );
    }

    render() {
 
        return (
            <table className="kt-datatable__table" style={{ minHeight: '500px' }}>
                {this.renderThead()}
                {this.renderBody()}
            </table>
        );
    }
}

export default withRouter(List);