import { urls } from '../constants/app';
import request from './utils';
import axios from 'axios';
import { setError, resetError } from './errors';

export function fetchGoods() {
    return (dispatch, getState) => {
    const goods = getState().goods.list;
    if (goods.length === 0) {
        dispatch({ type: 'SET_LOADER', field: 'goods', value: true });
        return axios.get('https://xn--161-9cd8an1avep9c.xn--p1ai/api/items')
            .then((response) => {
                dispatch({ type: 'SET_GOODS', goods: response.data });
                dispatch({ type: 'SET_LOADER', field: 'goods', value: false });
                return response;
            })
            .catch(error => {
                dispatch(setError(error, 'global'));
                dispatch({ type: 'SET_LOADER', field: 'goods', value: false });
                return null
            });
        }
    }
}


export function fetchCategories() {
    return (dispatch, getState) => {
    const categories = getState().goods.categories;
    if (categories.length === 0) {
        dispatch({ type: 'SET_LOADER', field: 'categories', value: true });
        return axios.get('https://xn--161-9cd8an1avep9c.xn--p1ai/api/category')
            .then((response) => {
                dispatch({ type: 'SET_CATEGORIES', categories: response.data });
                dispatch({ type: 'SET_LOADER', field: 'categories', value: false });
                return response;
            })
            .catch(error => {
                dispatch(setError(error, 'global'));
                dispatch({ type: 'SET_LOADER', field: 'categories', value: false });
                return null
            });
        }
    }
}


export function fetchOrders(dataParams = {}) {
    return (dispatch, getState) => {
    dispatch({ type: 'SET_LOADER', field: 'orders', value: true });
    const paging = getState().orders.paging;
    const sorting = getState().orders.sorting;
    const user = getState().user.info;
    const filters = getState().orders.filters;

    const params = {...dataParams};

    if (user.city) {
        params.city = user.city.id ;
    }

    if (filters.city) {
        dataParams['city.id'] = filters.city.value;
        params['city.id'] = filters.city.value;
    }

    if (filters.point) {
        dataParams['point.id'] = filters.point.value;
        params['point.id'] = filters.point.value;
    }

    if (filters.status) {
        dataParams['status'] = filters.status.value;
        params['status'] = filters.status.value;
    }

    params['_start'] = paging.start;
    params['_limit'] = paging.limit;

    params['_sort'] = `${sorting.field}:${sorting.type}`;

    request({ method: 'get', url: urls.orders+'/count', params: dataParams })
        .then(result => {
            dispatch({ type: 'SET_ORDERS_COUNT', count: result });
            return;
        })
        .then(() => {
            return request({ method: 'get', url: urls.orders, params }).then((response) => {
                dispatch({ type: 'SET_ORDERS', orders: response });
                dispatch({ type: 'SET_LOADER', field: 'orders', value: false });
            })
        })
        .catch(error => {
            dispatch(setError(error, 'orders'));
            dispatch({ type: 'SET_LOADER', field: 'orders', value: false });
        });
    }
}

export function deleteOrder(id) {
    return (dispatch) => {
    dispatch({ type: 'SET_LOADER', field: 'orders', value: true });
    dispatch(resetError('orders'));
    return request({ method: 'delete', url: `${urls.orders}/${id}` })
        .then((response) => {
            dispatch({ type: 'SET_LOADER', field: 'orders', value: false });
            return true;
        })
        .catch(error => {
            dispatch(setError({ status: 507 }, 'orders'));
            dispatch({ type: 'SET_LOADER', field: 'orders', value: false });
            return false;
        });
    }
}

export function setOrdersFilters(filters) {
    return dispatch => {
        dispatch({ type: 'SET_ORDERS_FILTERS', filters });
    }
}

export function updateOrder(id, data) {
    return (dispatch) => {
    dispatch({ type: 'SET_LOADER', field: 'order', value: true });
    dispatch(resetError('orders'));
    return request({ method: 'put', url: `${urls.orders}/${id}`, data })
        .then((response) => {
            dispatch({ type: 'SET_ORDER', order: response });
            dispatch({ type: 'SET_LOADER', field: 'order', value: false });
            return true;
        })
        .catch(error => {
            dispatch(setError({ status: 508 }, 'orders'));
            dispatch({ type: 'SET_LOADER', field: 'order', value: false });
            return false;
        });
    }
}

export function fetchOrder(id) {
    return (dispatch) => {
    dispatch({ type: 'SET_LOADER', field: 'order', value: true });

    return request({ method: 'get', url: `${urls.orders}/${id}` })
        .then((response) => {
            dispatch({ type: 'SET_ORDER', order: response });
            dispatch({ type: 'SET_LOADER', field: 'order', value: false });
            return response;
        })
        .catch(error => {
            dispatch(setError(error, 'order'));
            dispatch({ type: 'SET_LOADER', field: 'order', value: false });
            return null
        });
    }
}

export function setOrdersPaging(paging) {
    return dispatch => {
        dispatch({ type: 'SET_ORDERS_PAGING', paging });
    }
}