const initialState = {
    singIn: null,
    global: null,
    register: null
};

export default function errors(state = initialState, action) {
  switch(action.type) {
      case 'SET_ERROR': {
          return {
              ...state,
              [action.field]: action.value
          };
      }
    default: return state;
  }
}