const initialState = {
    info: null,
    setting: null
};

export default function user(state = initialState, action) {
  switch(action.type) {
      case 'SET_USER_INFO': {
          return {
              ...state,
              info: action.user
          };
      }
    default: return state;
  }
}