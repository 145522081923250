import React, { Component } from 'react'
import { connect } from 'react-redux'

export class SavedMessage extends Component {
    render() {
        return (
            <div className="kt-grid__item kt-grid__item--fluid    kt-portlet    kt-inbox__list kt-inbox__list--shown" id="kt_inbox_list" style={{ display: 'flex' }}>
                <div className="kt-portlet__head">
                    <div className="kt-inbox__toolbar kt-inbox__toolbar--extended">
                        <div className="kt-inbox__actions kt-inbox__actions--expanded">
                            <div className="kt-inbox__check">
                            <h5 style={{ marginBottom: 0 }}>Черновики</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="kt-portlet__body kt-portlet__body--fit">
                    
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(SavedMessage)
