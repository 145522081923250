export function getCookie(name) {
    const field = name.replace(/([.$?*|{}()[]\\\/+^])/g, '\\$1');
    let matches = document.cookie.match(new RegExp(
      "(?:^|; )" + field + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}

export function setCookie(name, value, options = {}) {
    options = {
      path: '/',
      ...options
    };
  
    if (options.expires && options.expires.toUTCString) {
      options.expires = options.expires.toUTCString();
    }
  
    let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);
  
    for (let optionKey in options) {
      updatedCookie += "; " + optionKey;
      let optionValue = options[optionKey];
      if (optionValue !== true) {
        updatedCookie += "=" + optionValue;
      }
    }
    document.cookie = updatedCookie;
}

export function deleteCookie(name) {
    setCookie(name, "", {
      'max-age': -1
    })
}
  