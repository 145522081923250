const initialState = {
    singIn: false,
    global: true,
    users: false,
    user: true,
    register: false,
    cities: true,
    addCity: false,
    addFilial: false,
    city: true,
    editCity: false,
    orders: false,
    settings: true,
    promocodes: false,
    categories: true,
    chanels: true,
    message: false
};

export default function loaders(state = initialState, action) {
  switch(action.type) {
      case 'SET_LOADER': {
          return {
              ...state,
              [action.field]: action.value
          };
      }
    default: return state;
  }
}