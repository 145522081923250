import React from 'react';
import Main from './Main';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import rootReducer from './reducers';

const middlewareEnhancer = applyMiddleware(thunkMiddleware);
const composedEnhancers = compose(
  middlewareEnhancer
);

const store = createStore(rootReducer, composedEnhancers);

export default () => (
  <Provider store={store}>
      <Main />
  </Provider>
);