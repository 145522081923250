const initialState = {
    list: [],
    detail: null,
    paging: {
      start: 0,
      limit: 10
    },
    sorting: {
      field: 'id',
      type: 'desc'
    },
    filters: {
      city: null,
      point: null,
      status: null
    },
    count: 0
};

export default function orders(state = initialState, action) {
  switch(action.type) {
    case 'SET_ORDERS': {
          return {
              ...state,
              list: action.orders
          };
    }
    case 'SET_ORDERS_FILTERS': {
      return {
          ...state,
          filters: action.filters
      };
    }
    case 'SET_ORDERS_PAGING': {
      return {
          ...state,
          paging: action.paging
      };
}
    case 'SET_ORDERS_COUNT': {
      return {
          ...state,
          count: action.count
      };
    }
    case 'SET_ORDER': {
      return {
          ...state,
          detail: action.order
      };
    }
    default: return state;
  }
}