import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Loader from '../components/utils/Loader';
import Portlet from '../components/utils/Portlet';
import Content from '../components/layout/Content';
import { fetchGoods, fetchCategories } from '../action/orders';
import cn from 'classnames';

const styles = {
    loading: {
        minHeight: '500px',
        display: 'flex',
        justifyContent: 'center'
    }
}

class Table extends Component {
    renderGood = (good, index) => {
        return (
            <tr key={index}>
                <td>
                    <span >
                        <div className="kt-user-card-v2">
                            <div className="kt-user-card-v2__pic">
                                <img alt={good.title} src={good.image}/>
                            </div>
                            <div className="kt-user-card-v2__details">
                                <span className="kt-user-card-v2__name">{good.title}</span>
                            </div>
                        </div>
                    </span>
                </td>
                <td>{good.description || '-'}</td>
                <td>{good.weight} гр.</td>
                <td className="kt-align-right kt-font-brand kt-font-bold">{good.price} руб.</td>
            </tr>
        );  
    }

    render() {
        return (
           
            <div className="kt-widget11">
                <div className="table-responsive">					 
                    <table className="table">
                        <thead>
                            <tr>
                                <td style={{ width: '30%' }}>Наименование</td>
                                <td style={{ width: '50%' }}>Описание</td>
                                <td style={{ width: '10%' }}>Вес</td>
                                <td style={{ width: '10%' }} className="kt-align-right">Цена</td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.props.items.map(this.renderGood)
                            }
                        </tbody>									     
                    </table>
                </div>
            </div>
        );
    }
}


class GoodsPage extends Component {
    componentDidMount() {
       this.props.fetchGoods();
       this.props.fetchCategories();
    }

    state = {
        category: this.props.categories.length ? this.props.categories[0].id : null
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.categories.length !== nextProps.categories.length) {
            this.setState({ category: nextProps.categories[0].id });
        }
    }


    renderSubHeader() {
        return (
            <div className="kt-subheader kt-grid__item">
                <div className="kt-container  kt-container--fluid ">
                <div className="kt-subheader__main">
                    <h3 className="kt-subheader__title">Товары</h3>
                    <span className="kt-subheader__separator kt-subheader__separator--v"></span>
                    <div className="kt-subheader__group" id="kt_subheader_search">
                        <span className="kt-subheader__desc" id="kt_subheader_total">
                            
                        </span>
                    </div>
                </div>
                
               

            </div>
            </div>
        );
    }

    setCategory = (id) => (e) => {
        e.preventDefault();
        this.setState({ category: id });
    }

    renderContent() {
        return (
            <div className="kt-grid kt-grid--desktop kt-grid--ver-desktop  kt-todo" id="kt_todo">
                <div className="kt-grid__item   kt-portlet kt-todo__aside" id="kt_todo_aside" style={{ opacity: 1 }}>
                    <div className="kt-todo__nav">
                        <ul className="kt-nav">
                          
                            {
                                this.props.categories.map(item => (
                                    <li key={item.id} className={cn({ 'kt-nav__item': true, 'kt-nav__item--active': item.id === this.state.category })}>
                                        <span onClick={this.setCategory(item.id)} className="kt-nav__link" data-action="list">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon kt-nav__link-icon">
                                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                        <rect x="0" y="0" width="24" height="24"/>
                                                        <path d="M12,21 C7.02943725,21 3,16.9705627 3,12 C3,7.02943725 7.02943725,3 12,3 C16.9705627,3 21,7.02943725 21,12 C21,16.9705627 16.9705627,21 12,21 Z M12,18 C15.3137085,18 18,15.3137085 18,12 C18,8.6862915 15.3137085,6 12,6 C8.6862915,6 6,8.6862915 6,12 C6,15.3137085 8.6862915,18 12,18 Z" fill="#000000"/>
                                                        <path d="M12,16 C14.209139,16 16,14.209139 16,12 C16,9.790861 14.209139,8 12,8 C9.790861,8 8,9.790861 8,12 C8,14.209139 9.790861,16 12,16 Z" fill="#000000" opacity="0.3"/>
                                                    </g>
                                                </svg>
                                            <span className="kt-nav__link-text">{item.title}</span>
                                        </span>
                                    </li>
                                ))
                            }
                            
                        </ul>
                    </div>
                </div>
   
                <div className="kt-grid__item kt-grid__item--fluid kt-todo__content" id="kt_todo_content">
                    <div className="kt-todo__tasks-bottom">
                        <Portlet>
                            <Table items={this.props.goods.filter(item => item.category === +this.state.category)}/>
                        </Portlet>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        if (this.props.loadingGoods || this.props.loadingCategories) {
            return (
                <div style={styles.loading}><Loader /></div>
            );
        }
        
        return (
            <Fragment>
                { this.renderSubHeader() }
                <div className="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                    <Content>
                    { this.renderContent() }
                    </Content>
                    
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    goods: state.goods.list,
    categories: state.goods.categories,
    loadingGoods: state.loaders.goods,
    loadingCategories: state.loaders.categories
});

const mapDispatchToProps = {
    fetchGoods: () => fetchGoods(),
    fetchCategories: () => fetchCategories()
}

export default connect(mapStateToProps, mapDispatchToProps)(GoodsPage);