import React, { Component } from 'react'
import { connect } from 'react-redux'
import { fetchMessages, setMessagesPaging } from '../../action/messages';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import ErrorContainer from '../../components/utils/ErrorContainer';

export class DeletedMessage extends Component {
    componentDidMount() {
        this.getMessages();
    }

    state = {
        currentPage: 0
    }

    componentWillUnmount() {
        const { paging } = this.props;
        this.props.setMessagesPaging({ ...paging, start: 0 });
    }

    getMessages = () => {
        this.props.fetchMessages({ status: 'deleted' });
    }

    renderMessage = (item) => {
        return (
            <div key={item.id} className="kt-inbox__item kt-inbox__item--unread" data-id="1" data-type="inbox">
                <div className="kt-inbox__info">
                    <div className="kt-inbox__sender" data-toggle="view">
                        <div href="/" className="kt-inbox__author">{item.theme}</div>
                    </div>
                </div>
                <div className="kt-inbox__details" data-toggle="view">
                    <div className="kt-inbox__message">
                        <span className="kt-inbox__summary">{item.message}</span>
                    </div>
                    <div className="kt-inbox__labels">
                        {
                            item.chanels.map(chanel => {
                                if (chanel.key === 'user') {
                                    return (
                                    <span className="kt-inbox__label kt-badge kt-badge--unified-brand kt-badge--dark kt-badge--bold kt-badge--inline">{chanel.title}: {chanel.value.phone}</span>
                                    );
                                }
                                if (chanel.key === 'users') {
                                    return (
                                    <span className="kt-inbox__label kt-badge  kt-badge--success kt-badge--bold kt-badge--inline">{chanel.title}</span>
                                    );
                                }
                                if (chanel.key === 'all') {
                                    return (
                                    <span className="kt-inbox__label kt-badge  kt-badge--danger kt-badge--bold kt-badge--inline">{chanel.title}</span>
                                    );
                                }
                                if (chanel.key === 'city') {
                                    return (
                                        <span className="kt-inbox__label kt-badge kt-badge--warning kt-badge--bold kt-badge--inline">{chanel.title}</span>
                                    );
                                }
                                return (
                                    <span className="kt-inbox__label kt-badge kt-badge--dark kt-badge--bold kt-badge--inline">{chanel.title}</span>
                                );
                            })
                        }
                    </div>
                </div>
                <div className="kt-inbox__datetime" data-toggle="view">
                    {moment(item.created_at).format('DD.MM.YYYY HH:mm')}
                </div>
            </div>
        );
    }

    setPageLimit = (e) => {
        e.preventDefault();
        const updatedPaging = { start: 0, limit: e.target.value};
        this.setState({ currentPage: 0 });
        this.props.setMessagesPaging(updatedPaging);
        this.getMessages();
    }

    handlePageClick = data => {
        const { paging } = this.props;
        this.setState({ currentPage: data.selected }, () => {
            let nextStart =  data.selected * paging.limit;
            const updatedPaging = {...paging, start: nextStart};
            this.props.setMessagesPaging(updatedPaging);
            this.getMessages();
        });
    };

    renderPagination() {
        const { count, paging } = this.props;
        const pages = Math.ceil(+count / +paging.limit);

        return (
            <div className="kt-datatable kt-datatable--default">
            <div className="kt-datatable__pager kt-datatable--paging-loaded">
                <ReactPaginate
                    previousLabel={<i className="flaticon2-back"></i>}
                    nextLabel={<i className="flaticon2-next"></i>}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={pages}
                    marginPagesDisplayed={8}
                    pageRangeDisplayed={5}
                    forcePage={this.state.currentPage}
                    onPageChange={this.handlePageClick}
                    containerClassName={'kt-datatable__pager-nav'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                    pageLinkClassName="kt-datatable__pager-link kt-datatable__pager-link-number"
                    previousLinkClassName="kt-datatable__pager-link kt-datatable__pager-link--prev"
                    nextLinkClassName="kt-datatable__pager-link kt-datatable__pager-link--next"
                    activeLinkClassName="kt-datatable__pager-link--active"
                    disabledClassName="kt-datatable__pager-link--disabled"
                />

                    <div className="kt-datatable__pager-info">
                        <div className="kt-datatable__pager-size">
                            <select onChange={this.setPageLimit} value={paging.limit} className="form-control">
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                            
                        </div>
                        {
                            paging.start + paging.limit >= count ? (
                                <span className="kt-datatable__pager-detail">Показано {paging.start + 1} - {+count} из {count}</span>
                            ) : (
                                <span className="kt-datatable__pager-detail">Показано {paging.start + 1} - {+paging.start + +paging.limit} из {count}</span>
                            )
                        }
                       
                    </div>
            </div>
            </div>
        );
    }
    
    render() {
        return (
            <div className="kt-grid__item kt-grid__item--fluid    kt-portlet    kt-inbox__list kt-inbox__list--shown" id="kt_inbox_list" style={{ display: 'flex' }}>
                <div className="kt-portlet__head">
                    <div className="kt-inbox__toolbar kt-inbox__toolbar--extended">
                        <div className="kt-inbox__actions kt-inbox__actions--expanded">
                            <div className="kt-inbox__check">
                            <h5 style={{ marginBottom: 0 }}>Удаленные уведомления ({this.props.count})</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="kt-portlet__body kt-portlet__body--fit">
                    <div className="kt-inbox__items" data-type="inbox">
                        { this.props.messages.map(this.renderMessage) }
                    </div>
                    {
                            this.props.messages.length > 0 && this.renderPagination()
                    }
                </div>
                <ErrorContainer field="messages" style={{ margin: 15 }} hasCloseButton={false}/>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    messages: state.messages.deleted.list,
    count: state.messages.deleted.count,
    paging: state.messages.deleted.paging
})

const mapDispatchToProps = {
    fetchMessages: (params) => fetchMessages('deleted', params),
    setMessagesPaging: (paging) => setMessagesPaging('deleted', paging)
}

export default connect(mapStateToProps, mapDispatchToProps)(DeletedMessage)
