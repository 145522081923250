import React, { Component } from 'react';
import ActionMenu from '../ActionMenu';
import { withRouter  } from 'react-router-dom';

class Table extends Component {
    renderThead() {
        return (
            <thead className="kt-datatable__head">
                <tr className="kt-datatable__row">
                    <th className="kt-datatable__cell">
                        <span style={{ width: '140px' }}>Имя</span>
                    </th>
                    <th className="kt-datatable__cell">
                        <span style={{ width: '123px' }}>Телефон</span>
                    </th>
                    <th className="kt-datatable__cell">
                        <span style={{ width: '123px' }}>Город</span>
                    </th>
                    <th className="kt-datatable__cell">
                        <span style={{ width: '150px' }}>Адрес доставки</span>
                    </th>
                    <th className="kt-datatable__cell">
                        <span style={{ width: '150px' }}>Пункт самовывоза</span>
                    </th>
                    <th className="kt-datatable__cell">
                        <span style={{ width: '110px' }}>
                            Статус
                        </span>
                    </th>
                    <th className="kt-datatable__cell">
                        <span style={{ width: '80px' }}>Действия</span>
                    </th>
                </tr>
            </thead>
        );
    }
    
    handleDoubleClick = (item) => () => {
        this.props.history.push(`/users/${+item.id}`)
    }
    
    renderAdress(item) {
        if (!item.street) return '-';
        return `${item.street}, ${item.house}${item.entrance ? `, подъезд ${item.entrance}` : ''}${item.room ? `, кв./оф. ${item.room}` : ''}`;
    }

    renderBody() {

        return (
            <tbody className="kt-datatable__body">
                {
                    this.props.items.length === 0 && (
                        <tr className="kt-datatable__row">
                            <td className="kt-datatable__cell">Пользователей не найдено</td>
                        </tr>
                    )
                }
                {
                    this.props.items.map(item => {
                        const actions = [
                            { name: 'Просмотр', icon: 'flaticon2-expand', action: () => this.props.history.push(`/users/${+item.id}`) },
                            { name: 'Редактирование', icon: 'flaticon2-contract', action: () => this.props.history.push(`/users/edit/${+item.id}`) },
                            { name: 'Push сообщение', icon: 'flaticon2-mail-1', action: () => this.props.onUserMessage(item) },
                            //{ name: 'Заблокировать', icon: 'flaticon-stopwatch', action: () => console.log(item) },
                            { name: 'Удалить', icon: 'flaticon2-trash', action: () => this.props.onDelete(item.id) }
                        ].filter(action => {
                            if (action.name === "Push сообщение") {
                                if (item.deviceToken) {
                                    return true;
                                }
                                return false;
                            } else {
                                return true;
                            }
                        });
                        return (
                            <tr onDoubleClick={this.handleDoubleClick(item)} key={item.id} className="kt-datatable__row">
                            <td className="kt-datatable__cell">
                                <span style={{ width: '140px' }}>{item.name ? item.name : '---'}</span>
                            </td>

                            <td className="kt-datatable__cell">
                                <span style={{ width: '123px' }}>+{item.phone}</span>
                            </td>
                            <td className="kt-datatable__cell">
                                <span style={{ width: '123px' }}>{item.city.name}</span>
                            </td>
                            <td className="kt-datatable__cell">
                                <span style={{ width: '150px' }}>
                                  { item && this.renderAdress(item) }
                                </span>
                            </td>
                            <td className="kt-datatable__cell">
                                <span style={{ width: '150px' }}>
                                   { item.points[0] && this.renderAdress(item.points[0]) }
                                </span>
                            </td>
                            <td className="kt-datatable__cell--sorted kt-datatable__cell">
                                <span style={{ width: '110px' }}>
                                    {
                                        item.confirmed ? (
                                            <span className="kt-badge kt-badge--brand kt-badge--inline">активен</span>
                                        ) : (
                                            <span className="kt-badge kt-badge--warning kt-badge--inline">не подтвержден</span>
                                        )
                                    }
                                </span>
                            </td>
                            <td className="kt-datatable__cell">
                                <ActionMenu actions={actions}/>
                            </td>
                        </tr>
                        )
                    }) 
                }
            </tbody>
        );
    }

    render() {
        return (
            <table className="kt-datatable__table" style={{ minHeight: '500px' }}>
                {this.renderThead()}
                {this.renderBody()}
            </table>
        );
    }
}

export default withRouter(Table);