import React, {Component} from 'react';
import Footer from '../components/layout/Footer';
import Sidebar from '../components/layout/Sidebar';
import {Route, Redirect} from "react-router-dom";
import { pages } from '../constants/page';
import { connect } from 'react-redux';
import { getCurrentUser } from '../action/user';
import LoadingPage from './LoadingPage';
import Header from '../components/layout/Header';

class MainPage extends Component {
  componentDidMount() {
    this.props.getCurrentUser();
  }

  renderError() {
    return (
      <div style={{ width: '100%' }} className="kt-quick-panel--right kt-demo-panel--right kt-offcanvas-panel--right kt-header--fixed kt-header-mobile--fixed kt-subheader--fixed kt-subheader--solid kt-aside--enabled kt-aside--fixed kt-page--loading kt-footer--fixed">
        <div className="error-page">   
          <div className="alert alert-danger" role="alert">
            <div className="alert-icon"><i className="flaticon-warning"></i></div>
            <div className="alert-text">{this.props.error}</div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    if (this.props.loading) return <LoadingPage />;
    if (this.props.error)  return this.renderError();

    return (
      <div style={{ width: '100%' }} className="kt-quick-panel--right kt-demo-panel--right kt-offcanvas-panel--right kt-header--fixed kt-header-mobile--fixed kt-subheader--fixed kt-subheader--solid kt-aside--enabled kt-aside--fixed kt-page--loading kt-footer--fixed">
        <div className="kt-header-mobile  kt-header-mobile--fixed ">
          <div className="kt-header-mobile__logo"></div>
          <div className="kt-header-mobile__toolbar">
            <button
              className="kt-header-mobile__toggler kt-header-mobile__toggler--left"
              id="kt_aside_mobile_toggler">
              <span></span>
            </button>
            <button className="kt-header-mobile__toggler" id="kt_header_mobile_toggler">
              <span></span>
            </button>
            <button
              className="kt-header-mobile__topbar-toggler"
              id="kt_header_mobile_topbar_toggler">
              <i className="flaticon-more"></i>
            </button>
          </div>
        </div>

        <div className="kt-grid kt-grid--hor kt-grid--root">
          <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
            <Sidebar user={this.props.user}/>
            <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
            
            <Header />
              {
                pages.map(page => {

                  return (
                    <Route
                      key={page.name}
                      exact={page.path === '/'}
                      path={page.path}
                      render={props => {
                        if (page.access && page.access !== this.props.user.role.id) {
                          return <Redirect to={{ pathname: "/" }} />;
                        }
                        const Component = page.component;
                        return <Component {...props}/>
                      }}
                    />
                  )
                }) 
              }
            

            <Footer/>

          </div>
          </div>

          </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.loaders.global,
  user: state.user.info,
  error: state.errors.global
});

const mapDispatchToProps = {
  getCurrentUser: () => getCurrentUser()
}


export default connect(mapStateToProps, mapDispatchToProps)(MainPage);