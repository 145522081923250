import React, {Component, Fragment} from 'react';
import { connect } from 'react-redux';
import { fetchUsers } from '../action/user';
import { handbookPages } from '../constants/page';
import {Route, Redirect} from 'react-router-dom';
import Content from '../components/layout/Content';

class Handbook extends Component {
    render() {
        return (
            <Fragment>
                { 
                    handbookPages.map(page => {
                        return (
                            <Route
                                key={page.name}
                                exact
                                path={page.path}
                                render={props => {
                                    if (page.access && page.access !== this.props.auth.role.id) {
                                        return <Redirect to="/"/>
                                    }

                                    if (props.match.params.id && !/\d+/.test(props.match.params.id)) {
                                        return null;
                                    }
                                    
                                    const Component = page.component;
                                    return (
                                        <Content subheader={true} title={page.title} fit={page.fit}>
                                            <Component {...props}/>
                                        </Content>
                                    );
                                }}
                            />
                        );
                    })
                }
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    users: state.users.list,
    loading: state.loaders.content,
    auth: state.user.info
});

const mapDispatchToProps = {
    fetchUsers: () => fetchUsers()
}

export default connect(mapStateToProps, mapDispatchToProps)(Handbook);