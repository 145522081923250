import React, { Component } from 'react';
import ActionMenu from '../utils/ActionMenu';
import { withRouter  } from 'react-router-dom';
import moment from 'moment';

class OrdersTable extends Component {
    renderThead() {
        return (
            <thead className="kt-datatable__head">
                <tr className="kt-datatable__row">
                    <th className="kt-datatable__cell">
                        <span style={{ width: '100px' }}>Номер заказа</span>
                    </th>
                    <th className="kt-datatable__cell">
                        <span style={{ width: '110px' }}>
                            Дата
                        </span>
                    </th>
                    <th className="kt-datatable__cell">
                        <span style={{ width: '140px' }}>Имя</span>
                    </th>
                    <th className="kt-datatable__cell">
                        <span style={{ width: '123px' }}>Телефон</span>
                    </th>
                    <th className="kt-datatable__cell">
                        <span style={{ width: '123px' }}>Город</span>
                    </th>
                    {/*
                    <th className="kt-datatable__cell">
                        <span style={{ width: '200px' }}>Адрес доставки</span>
                    </th>
                    <th className="kt-datatable__cell">
                        <span style={{ width: '150px' }}>Пункт самовывоза</span>
                    </th>
                    */}
                    <th className="kt-datatable__cell">
                        <span style={{ width: '100px' }}>Стоимость</span>
                    </th>
                    <th className="kt-datatable__cell">
                        <span style={{ width: '123px' }}>Статус</span>
                    </th>
                    <th className="kt-datatable__cell">
                        <span style={{ width: '80px' }}>Действия</span>
                    </th>
                </tr>
            </thead>
        );
    }
    
    handleDoubleClick = (item) => () => {
        this.props.history.push(`/orders/${+item.id}`)
    }
    
    renderAdress(item) {
        if (!item.street) return '-';
        return `${item.street}, ${item.house}${item.entrance ? `, подъезд ${item.entrance}` : ''}${item.room ? `, кв./оф. ${item.room}` : ''}`;
    }

    renderStatus(status) {
        if (status === 'new') {
            return <span className="kt-badge kt-badge--brand kt-badge--inline">новый</span>
        }
        if (status === 'done') {
            return <span className="kt-badge kt-badge--brand kt-badge--inline kt-badge--success">выполнен</span>
        }
        if (status === 'cancel') {
            return <span className="kt-badge kt-badge--brand kt-badge--inline kt-badge--danger">отменен</span>
        }
    }

    renderBody() {

        return (
            <tbody className="kt-datatable__body">
                {
                    this.props.items.length === 0 && (
                        <tr className="kt-datatable__row">
                            <td className="kt-datatable__cell">Заказов не найдено</td>
                        </tr>
                    )
                }
                {
                    this.props.items.map(item => {
                        const actions = [
                            { name: 'Просмотр', icon: 'flaticon2-expand', action: () => this.props.history.push(`/orders/${+item.id}`) },
                            { name: 'Удалить', icon: 'flaticon2-trash', action: () => this.props.onDelete(item.id) }
                        ];
                        return (
                            <tr onDoubleClick={this.handleDoubleClick(item)} key={item.id} className="kt-datatable__row">
                            <td className="kt-datatable__cell">
                                <span style={{ width: '100px' }}>10{item.id}</span>
                            </td>
                            <td className="kt-datatable__cell">
                                <span style={{ width: '110px' }}>{moment(item.created_at).format('DD.MM.YYYY HH:mm')}</span>
                            </td>
                            <td className="kt-datatable__cell">
                                <span style={{ width: '140px' }}>{item.userName ? item.userName : '---'}</span>
                            </td>

                            <td className="kt-datatable__cell">
                                <span style={{ width: '123px' }}>+{item.phone}</span>
                            </td>
                            <td className="kt-datatable__cell">
                                <span style={{ width: '123px' }}>{item.city.name}</span>
                            </td>
                             {/*
                            <td className="kt-datatable__cell">
                                <span style={{ width: '200px' }}>
                                  { item && this.renderAdress(item) }
                                </span>
                            </td>
                            <td className="kt-datatable__cell">
                                <span style={{ width: '150px' }}>
                                   { item.point && this.renderAdress(item.point) }
                                </span>
                            </td>
                             */}
                            <td className="kt-datatable__cell">
                                <span style={{ width: '100px' }}>{item.totalPrice} руб.</span>
                            </td>
                            <td className="kt-datatable__cell">
                                <span style={{ width: '123px' }}>
                                    { this.renderStatus(item.status) }
                                </span>
                            </td>
                            <td className="kt-datatable__cell">
                                <ActionMenu actions={actions}/>
                            </td>
                        </tr>
                        )
                    }) 
                }
            </tbody>
        );
    }

    render() {
        return (
            <table className="kt-datatable__table" style={{ minHeight: '500px' }}>
                {this.renderThead()}
                {this.renderBody()}
            </table>
        );
    }
}

export default withRouter(OrdersTable);