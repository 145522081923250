import React, { Component } from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import { deleteCookie } from '../../utils/cookie';
import {withRouter, Redirect } from "react-router-dom";

class UserInfo extends Component {
    state = {
        show: false,
        exit: false
    }

    onShow = (e) => {
        this.setState({ show: !this.state.show });
    }

    singOut = (e) => {
      e.preventDefault();
      deleteCookie('auth');
      this.setState({ exit: true });
    }

    componentDidMount() {
        document.addEventListener('click', this.closeModal);
    }

    componentWillUnmount() {
      document.removeEventListener('click', this.closeModal);
    }
    

    closeModal = (evt) => {
      const flyoutElement = document.getElementById("userInfo");
      let targetElement = evt.target; 

    do {
        if (targetElement === flyoutElement) {
            return;
        }
        targetElement = targetElement.parentNode;
    } while (targetElement);
      this.setState({ show: false });
    }

    render() {
        if (this.state.exit) return <Redirect to="/login"/>;
        return (
            <div id="userInfo" className={cn({ 'kt-header__topbar-item': true, 'kt-header__topbar-item--user': true, show: this.state.show })}>
                  <div
                    onClick={this.onShow}
                    className="kt-header__topbar-wrapper"
                    data-toggle="dropdown"
                    data-offset="0px,0px">
                    <div className="kt-header__topbar-user">
                      <span className="kt-header__topbar-welcome kt-hidden-mobile">{this.props.userInfo.role.description},</span>
                      <span className="kt-header__topbar-username kt-hidden-mobile">{this.props.userInfo && this.props.userInfo.username}</span>
                      <img className="kt-hidden" alt="Pic" src="./assets/media/users/300_25.jpg"/>

                      <span
                        className="kt-badge kt-badge--username kt-badge--unified-success kt-badge--lg kt-badge--rounded kt-badge--bold">{this.props.userInfo && this.props.userInfo.username[0].toUpperCase()}</span>
                    </div>
                  </div>

                  <div className={cn({ 'dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl': true, show: this.state.show })}>

                    <div
                      className="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x"
                      style={{ backgroundImage: 'url(/images/bg-1.jpg)' }}>
                      <div className="kt-user-card__avatar">
                        <img className="kt-hidden" alt="Pic" src="./assets/media/users/300_25.jpg"/>

                        <span
                          className="kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-success">{this.props.userInfo && this.props.userInfo.username[0].toUpperCase()}</span>
                      </div>
                      <div className="kt-user-card__name">
                        {this.props.userInfo && this.props.userInfo.username}
                      </div>
                      
                    </div>

                    <div className="kt-notification">
                      

                      
                      <div className="kt-notification__custom kt-space-between">
                        <button onClick={this.singOut} className="btn btn-label btn-label-brand btn-sm btn-bold">Выход</button>
                      </div>
                    </div>

                  </div>
                </div>
        );
    }
}

const mapStateToProps = state => ({
    userInfo: state.user.info
});

export default withRouter(connect(mapStateToProps)(UserInfo));