const initialState = {
    init: {},
    edited: {}
};

export default function settings(state = initialState, action) {
  switch(action.type) {
        case 'SET_SETTINGS': {
            return {
                ...state,
                init: action.data,
                edited: action.data
            };
        }
        case 'SET_EDITED_SETTINGS': {
            return {
                ...state,
                edited: {
                    ...state.edited,
                    [action.field]: action.value
                }
            }
        }
        default: return state;
    }
}