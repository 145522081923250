export const baseUrl = 'http://192.168.0.105:1337';
export const urls = {
    singIn: `${baseUrl}/auth/local`,
    currentUser: `/users/me`,
    users: `${baseUrl}/users`,
    register: `/auth/local/register`,
    cities: '/cities',
    filials: '/points',
    orders: '/orders',
    settings: '/settings',
    promocodes: '/promocodes',
    cashback: '/cashback',
    chanels: '/chanels',
    messages: '/messages'
};