import { urls } from '../constants/app';
import request from './utils';
import { setError, resetError } from './errors';

export function fetchChanels() {
    return dispatch => {
        dispatch({ type: 'SET_LOADER', field: 'chanels', value: true });
        dispatch(resetError('chanels'));
        request({ method: 'get', url: urls.chanels })
            .then(chanels => {
                dispatch({ type: 'SET_CHANELS', chanels });
                dispatch({ type: 'SET_LOADER', field: 'chanels', value: false });
            })
            .catch(error => {
                dispatch(setError(error, 'chanels'));
                dispatch({ type: 'SET_LOADER', field: 'chanels', value: false });
            });
    }
}

export function fetchMessages(group, params) {
    return (dispatch, getState) => {
        const groupData = getState().messages[group];
        const { paging, sorting } = groupData;
        const data = {
            ...params
        };
        data['_start'] = paging.start;
        data['_limit'] = paging.limit;
        data['_sort'] = `${sorting.field}:${sorting.type}`;

        dispatch({ type: 'SET_LOADER', field: 'messages', value: true });
        dispatch(resetError('messages'));
        request({ method: 'get', url: urls.messages+'/count', params })
            .then(result => {
                dispatch({ type: 'SET_MESSAGES_COUNT', group, count: result });
                return;
            })
            .then(() => {
                return request({ method: 'get', url: urls.messages, params: data })
                    .then(messages => {
                        dispatch({ type: 'SET_MESSAGES', group, messages });
                        dispatch({ type: 'SET_LOADER', field: 'messages', value: false });
                    })
                    
            })
            .catch(error => {
                dispatch(setError(error, 'messages'));
                dispatch({ type: 'SET_LOADER', field: 'messages', value: false });
            });
        
    }
}

export function setMessagesPaging(group, paging) {
    return dispatch => {
        dispatch({ type: 'SET_MESSAGES_PAGING', paging, group });
    }
}

export function addMessage(data) {
    return dispatch => {
        dispatch({ type: 'SET_LOADER', field: 'message', value: true });
        dispatch(resetError('newMessage'));
        return request({ method: 'post', url: urls.messages, data })
            .then(response => {
                dispatch({ type: 'SET_LOADER', field: 'message', value: false });
                return response;
            })
            .catch(error => {
                dispatch(setError(error, 'newMessage'));
                dispatch({ type: 'SET_LOADER', field: 'message', value: false });
                return 'error';
            });
    }
}

export function updateMessage(id, data) {
    return dispatch => {
        dispatch({ type: 'SET_LOADER', field: 'messages', value: true });
        dispatch(resetError('messages'));
        return request({ method: 'put', url: `${urls.messages}/${id}`, data })
            .then(response => {
                dispatch({ type: 'SET_LOADER', field: 'messages', value: false });
                return response;
            })
            .catch(error => {
                dispatch(setError(error, 'messages'));
                dispatch({ type: 'SET_LOADER', field: 'messages', value: false });
                return false;
            });
    }
}

export function addChanel(data) {
    return request({ method: 'post', url: urls.chanels, data })
        .then(result => {
            return result;
        })
        .catch(error => {
            return error;
        });
}

export function setNewMessageForm(field, value) {
    return dispatch => {
        dispatch({ type: 'SET_NEW_MESSAGE_FORM', field, value });
    }
}

export function resetNewMessageForm() {
    return dispatch => {
        dispatch({ type: 'RESET_NEW_MESSAGE_FORM' });
    }
}