import React, { Component } from 'react';
import { connect } from 'react-redux';
import { resetError } from '../../action/errors';
import cn from 'classnames';

class ErrorContainer extends Component {
    onResetError = () => {
        this.props.resetError(this.props.field);
    }

    render() {
        if (!this.props.error) return null;

        return (
            <div style={this.props.style} className={cn({ 'alert fade show': true, 'alert-outline-danger': !this.props.container, 'alert-danger': this.props.container })}>
                <div className="alert-icon"><i className="flaticon-warning"></i></div>
                <div className="alert-text">{this.props.error}</div>
                {
                    this.props.hasCloseButton && (
                        <div className="alert-close">
                            <button onClick={this.onResetError} type="button" className="close">
                                <span><i className="la la-close"></i></span>
                            </button>
                        </div>
                    )
                }
            </div>
        );
    }
}

const mapStateToProps = (state, { field }) => ({
    error: state.errors[field]
});

const mapDispatchToProps = {
    resetError: (field) => resetError(field)
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorContainer);